import { getRecoil, setRecoil } from "recoil-nexus";

import Api from "../api";
import MessageAtom from "./message.atom";
import { GetMessageListParams, Message } from "./message.type";
import { StorageConstant } from "../storage";

const getList = async ({
  page,
  pageSize,
  offsetAdd,
  conversation,
}: GetMessageListParams) => {
  try {
    const locale = localStorage.getItem(StorageConstant.LANGUAGE) || "vi";
    const offset = (page - 1) * pageSize + offsetAdd;

    const res: any = await Api({
      url: "/v1/messages",
      method: "GET",
      params: { offset, limit: pageSize, conversation, locale },
    });

    const currentValues = getRecoil(MessageAtom.list);

    setRecoil(MessageAtom.list, {
      ...currentValues,
      data: res?.data?.reverse()?.concat(currentValues.data),
      total: res?.total || 0,
    });

    return {
      total: res.total || 0,
    };
  } catch (error) {
    return {
      total: 0,
    };
  }
};

const createMessage = async (data: Partial<Message>) => {
  try {
    const res: any = await Api({
      url: "/v1/messages",
      method: "POST",
      data,
    });

    return res?.statusCode === 200;
  } catch (error) {
    return null;
  }
};

const markAsRead = async (conversation: string) => {
  try {
    const res: any = await Api({
      url: "/v1/messages/mark-as-read",
      method: "GET",
      params: {
        conversation,
      },
    });

    return res?.statusCode === 200;
  } catch (error) {
    return null;
  }
};

const MessageApi = {
  getList,
  createMessage,
  markAsRead,
};

export default MessageApi;
