import { setRecoil } from "recoil-nexus";
import { toast } from "react-toastify";
import { get } from "lodash";

import Api from "../api";
import { IGetDetailsUserResponse, UserRolesEnum } from "./user.type";
import UserAtom from "./user.atom";
import { IInformationValidation } from "~/utils/validation";
import { StorageAtom, StorageConstant } from "../storage";
import Trans from "~/translation/translationKeys";
import i18n from "~/translation";

const getDetails = async () => {
  try {
    const res = await Api<IGetDetailsUserResponse>({
      url: "/v1/users/me",
      method: "GET",
    });

    const userProfile = res.data?.user_profile;

    if (userProfile.role === UserRolesEnum.SERVICE_OWNER) {
      setRecoil(UserAtom.userInfo, userProfile);
      return userProfile;
    }

    return undefined;
  } catch (error) {
    return undefined;
  }
};

const updateInfo = async (data: IInformationValidation) => {
  try {
    const res = await Api<CommonResponse>({
      url: "/v1/customers/profiles",
      method: "PATCH",
      data,
    });

    const dataRes: any = get(res, "data");

    if (dataRes.statusCode === 200) {
      toast.success(dataRes.message);
      return dataRes;
    }

    return undefined;
  } catch (error) {
    return undefined;
  }
};

const deleteCustomer = async (customerId: string) => {
  try {
    const res = await Api<CommonResponse>({
      url: `/v1/customers/${customerId}`,
      method: "DELETE",
    });

    const dataRes: any = get(res, "data");

    if (dataRes) {
      toast.success(
        i18n.t(Trans.name_successful, {
          text: i18n.t(Trans.delete_account),
        })
      );
      localStorage.removeItem(StorageConstant.ACCESS_TOKEN);
      setRecoil(StorageAtom.token, "");
      setRecoil(UserAtom.userInfo, undefined);

      return dataRes;
    }

    return undefined;
  } catch (error) {
    return undefined;
  }
};

const UserApi = { getDetails, updateInfo, deleteCustomer };

export default UserApi;
