import React, { useEffect } from "react";

import { Router } from "~/router";
import ModalPayment from "./components/ModalPayment";
import ModalConfirm from "./components/ModalConfirm";
import ChatModal from "./components/ChatModal";
import useSocket from "./hooks/useSocket";
import { CartAPI } from "./services/cart";
import { useRecoilValue } from "recoil";
import { UserAtom } from "./services/user";

const App: React.FC = () => {
  const user = useRecoilValue(UserAtom.userInfo);
  useSocket();

  // Handle Open URL Onepay
  // useEffect(() => {
  //   if (url) {
  //     const windowOpen = window.open(url, "_blank");

  //     ref.current = windowOpen;

  //     timer.current = setInterval(() => {
  //       if (windowOpen?.closed && timer.current) {
  //         clearInterval(timer.current);
  //         setLoading(false);
  //       }
  //     }, 1000);
  //   } else {
  //     ref.current?.close();
  //   }
  // }, [url, setLoading]);

  // useEffect(() => {
  //   return () => {
  //     if (timer.current) {
  //       clearInterval(timer.current);
  //     }
  //   };
  // }, []);

  useEffect(() => {
    CartAPI.getList();
  }, [user?._id]);

  return (
    <React.Fragment>
      <Router />

      <ModalPayment />
      <ModalConfirm />
      <ChatModal />
    </React.Fragment>
  );
};

export default App;
