import { atom } from "recoil";

import { ILocale } from "./common.type";

const locales = atom<ILocale[]>({
  key: "locales",
  default: [],
});

interface OpenChatModal {
  open: boolean;
  conversationId?: string;
}

const openChatModal = atom<OpenChatModal>({
  key: "openChatModal",
  default: {
    open: false,
  },
});

interface ShowModalConfirm {
  open: boolean;
  onConfirm?: () => Promise<void> | void;
  title?: string;
  description?: string;
}

const showModalConfirm = atom<ShowModalConfirm>({
  key: "showModalConfirm",
  default: {
    open: false,
  },
});

const CommonAtom = { locales, openChatModal, showModalConfirm };

export default CommonAtom;
