import { getRecoil } from "recoil-nexus";

import { StorageConstant } from "~/services/storage";
import { UserAtom } from "~/services/user";

const getCustomerId = () => {
  const user = getRecoil(UserAtom.userInfo);
  const customer_id =
    localStorage.getItem(StorageConstant.CUSTOMER_ID) || undefined;
  const token = localStorage.getItem(StorageConstant.ACCESS_TOKEN);

  if (user?._id && token) {
    return user?._id;
  }

  return customer_id;
};

export default getCustomerId;
