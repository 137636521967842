import { IOrder } from "../order";
import { IProduct } from "../products";
import { IUser } from "../user";

export enum MessageTypes {
  ORDER = "ORDER",
  PRODUCT = "PRODUCT",
  MESSAGE = "MESSAGE",
}

export interface Message {
  _id: string;
  type: MessageTypes;
  order_id?: string;
  product_id?: string;
  content?: string;
  createdAt: string;
  sender: IUser;
  order?: IOrder;
  product?: IProduct;
  conversation: string;
  receiver: string;
}

export interface GetMessageListParams {
  page: number;
  pageSize: number;
  offsetAdd: number;
  conversation: string;
}
