import classNames from "classnames";
import React, { useCallback, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import ReactLoading from "react-loading";
import AutoSizer from "react-virtualized-auto-sizer";
import { Virtuoso } from "react-virtuoso";
import { Avatar } from "antd";
import { NumericFormat } from "react-number-format";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import ProductDefaultIMG from "~/assets/images/product-default.png";

import { UserAtom } from "~/services/user";
import Pricing from "~/utils/pricing";
import Trans from "~/translation/translationKeys";
import { Message, MessageAtom, MessageTypes } from "~/services/message";
import { Conversation } from "~/services/conversation";
import checkIsLink from "~/utils/checkIsLink";

import "./ChatModal.less";

interface ChatBodyProps {
  loadingMessage: boolean;
  loadingLoadMore: boolean;
  ref: any;
  firstItemIndex: number;
  setLoadingLoadMore: React.Dispatch<React.SetStateAction<boolean>>;
  offsetAdd: number;
  conversationDetails?: Conversation;
}

const ChatBody: React.FC<ChatBodyProps> = ({
  loadingMessage,
  loadingLoadMore,
  ref,
  firstItemIndex,
  setLoadingLoadMore,
  offsetAdd,
  conversationDetails,
}) => {
  const user = useRecoilValue(UserAtom.userInfo);
  const { t } = useTranslation();
  const [messages, setMessages] = useRecoilState(MessageAtom.list);
  const [showMoreProductId, setShowMoreProductId] = useState("");

  const renderText = useCallback((message?: string) => {
    if (!message) {
      return "";
    }

    const regExp =
      /((?:http|https|ftp|ftps)\:\/\/[a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,3}(?:\/\S*)?)/g;
    const textParts = message.split(regExp);

    return textParts.map((text, index) =>
      text && checkIsLink(text) ? (
        <a href={text} target="_blank" key={index} rel="noreferrer">
          {text}
        </a>
      ) : (
        <span key={index}>{text}</span>
      )
    );

    /*eslint no-useless-escape: */
  }, []);

  const renderItem = useCallback(
    (_: number, message: Message) => {
      switch (message.type) {
        case MessageTypes.MESSAGE: {
          const isMine = message.sender?._id === user?._id;

          return (
            <div
              className={classNames("message_container", {
                mine: isMine,
              })}
            >
              {!isMine && (
                <Avatar
                  size={24}
                  className="avatar"
                  style={{ backgroundColor: conversationDetails?.color }}
                >
                  {String(
                    (conversationDetails?.user_1?._id === user?._id
                      ? conversationDetails?.user_2?.name
                      : conversationDetails?.user_1?.name) || "-"
                  ).charAt(0)}
                </Avatar>
              )}

              <div className="message_content">
                <p className="description">{renderText(message.content)}</p>
                <p className="date">
                  {format(new Date(message.createdAt), "HH:mm dd/MM/yy")}
                </p>
              </div>
            </div>
          );
        }

        case MessageTypes.PRODUCT: {
          const isActive = showMoreProductId === message?.product?._id;

          return (
            <div className="product">
              <p className="product_text">
                {t(Trans.you_are_discussing_this_product)}
              </p>

              <div className="product_box">
                <div className="product_content">
                  <img
                    src={message?.product?.images?.[0] || ProductDefaultIMG}
                    alt={message?.product?.name}
                    className="image"
                  />

                  <div className="flex flex-col justify-between flex-1">
                    <div className="flex justify-between">
                      <h3 className="name">{message?.product?.name || "-"}</h3>

                      <NumericFormat
                        className="price"
                        value={
                          Pricing.calculateProductPrice(
                            message?.product?.price,
                            message?.product?.discount,
                            message?.product?.discountType
                          ) || 0
                        }
                        thousandSeparator=","
                        displayType="text"
                        suffix=" VNĐ"
                      />
                    </div>

                    <p
                      className="see_more"
                      onClick={() =>
                        setShowMoreProductId(
                          isActive ? "" : message?.product?._id || ""
                        )
                      }
                    >
                      {isActive ? t("see_less") : t("see_more")}
                    </p>
                  </div>
                </div>

                {isActive && (
                  <p className="description">{message?.product?.description}</p>
                )}
              </div>
            </div>
          );
        }
      }
    },
    [user, t, conversationDetails, showMoreProductId, renderText]
  );

  const onLoadMore = () => {
    const total_page = Math.ceil(messages.total / messages.pageSize);

    if (messages.page < total_page && !loadingLoadMore) {
      setLoadingLoadMore(true);

      setMessages((currentMessages) => ({
        ...currentMessages,
        page: currentMessages.page + 1,
        offsetAdd,
      }));
    }
  };

  return (
    <div className="chat-modal-body">
      {(loadingMessage || !user) && !messages.total && (
        <div className="loading">
          <ReactLoading color="#7D828B" width={40} height={40} type="bubbles" />
        </div>
      )}

      <AutoSizer>
        {({ width, height }: any) => (
          <Virtuoso
            ref={ref}
            style={{ height, width }}
            data={user ? messages.data : []}
            totalCount={messages.total + offsetAdd}
            itemContent={renderItem}
            initialTopMostItemIndex={messages.total + offsetAdd - 1}
            alignToBottom
            followOutput="smooth"
            firstItemIndex={firstItemIndex}
            startReached={onLoadMore}
            components={{
              Header: () =>
                loadingLoadMore ? (
                  <ReactLoading
                    width={36}
                    height={36}
                    type="bubbles"
                    className="mx-auto"
                    color="#7D828B"
                  />
                ) : null,
            }}
          />
        )}
      </AutoSizer>
    </div>
  );
};

export default ChatBody;
