import { atom } from "recoil";

import { IOrder } from "../order";
import { IReservation } from "../reservation";
import { IUser } from "./user.type";

interface IOrderHistoryList {
  page: number;
  limit: number;
  data: IOrder[];
  total: number;
}

interface IReservationHistoryList {
  page: number;
  limit: number;
  data: IReservation[];
  total: number;
}

const userInfo = atom<IUser | undefined>({
  key: "user_info",
  default: undefined,
});

const orderHistory = atom<IOrderHistoryList>({
  key: "order_history",
  default: {
    page: 1,
    limit: 6,
    data: [],
    total: 0,
  },
});

const reservationHistory = atom<IReservationHistoryList>({
  key: "reservation_history",
  default: {
    page: 1,
    limit: 6,
    data: [],
    total: 0,
  },
});

const UserAtom = { userInfo, orderHistory, reservationHistory };

export default UserAtom;
