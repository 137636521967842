import { atom } from "recoil";

const token = atom<string>({
  key: "token",
  default: "",
});

const url = atom<string>({
  key: "url",
  default: "",
});

const StorageAtom = { token, url };

export default StorageAtom;
