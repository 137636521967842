import { get } from "lodash";
import { toast } from "react-toastify";
import { getRecoil, setRecoil } from "recoil-nexus";

import Api from "../api";
import { UserAtom } from "../user";
import {
  CreateOrderProps,
  ICreateOrderResponse,
  IGetOrderDetailResponse,
  IGetOrderHistoryResponse,
  OrderStatusEnum,
} from "./order.type";
import { StorageConstant } from "../storage";

const getOrderHistoryByCustomer = async (page = 1, limit = 6) => {
  try {
    const res = await Api<IGetOrderHistoryResponse>({
      url: "/v1/customers/orders",
      method: "GET",
      params: { offset: (page - 1) * limit, limit },
    });

    const dataRes = get(res, "data");

    if (dataRes) {
      setRecoil(UserAtom.orderHistory, {
        ...getRecoil(UserAtom.orderHistory),
        data: dataRes.orders,
        total: dataRes.total,
      });
      return dataRes.orders;
    }

    return undefined;
  } catch (error) {
    return undefined;
  }
};

const getOrderDetail = async (orderId: string) => {
  try {
    const res = await Api<IGetOrderDetailResponse>({
      url: `/v1/orders/${orderId}`,
      method: "GET",
    });

    if (res.data.statusCode === 200) {
      return res.data.order;
    }

    return undefined;
  } catch (error) {
    return undefined;
  }
};

const sendReceipt = async (orderId: string) => {
  try {
    const locale = localStorage.getItem(StorageConstant.LANGUAGE) || "vi";

    const res = await Api<CommonResponse>({
      url: `/v1/orders/${orderId}/send-receipt`,
      method: "POST",
      params: {
        locale,
      },
    });

    const dataRes: any = get(res, "data");

    if (dataRes.statusCode === 200) {
      toast.success(dataRes.message);
      return dataRes;
    }

    return undefined;
  } catch (error) {
    return undefined;
  }
};

const createOrder = async (data: CreateOrderProps) => {
  try {
    const locale = localStorage.getItem(StorageConstant.LANGUAGE) || "vi";

    const res = await Api<ICreateOrderResponse>({
      url: "/v1/orders",
      method: "POST",
      params: {
        locale,
      },
      data,
    });

    if (res.data.statusCode === 201) {
      return res.data;
    }

    return undefined;
  } catch (error) {
    return undefined;
  }
};

const updateStatus = async (orderId: string, orderStatus: OrderStatusEnum) => {
  try {
    const resp = await Api<CommonResponse>({
      url: `/v1/orders/${orderId}/status`,
      method: "PUT",
      data: {
        status: orderStatus,
      },
    });

    return resp;
  } catch (error) {
    throw error;
  }
};

const OrderApi = {
  getOrderHistoryByCustomer,
  getOrderDetail,
  sendReceipt,
  createOrder,
  updateStatus,
};

export default OrderApi;
