import { setRecoil, getRecoil } from "recoil-nexus";
import { toast } from "react-toastify";

import Api from "../api";
import ProductAtom from "./products.atom";
import {
  ApprovalStatus,
  ICreateUpdateProductResponse,
  IGetCategoriesResponse,
  IGetProductDetailsResponse,
  IGetProductListResponse,
  IGetProductsResponse,
  IProduct,
  IUploadImageResponse,
  ProductStatus,
} from "./products.type";
import { StorageConstant } from "../storage";
import i18n from "~/translation";
import Trans from "~/translation/translationKeys";

// const getProductsByCategory = async ({
//   companyId,
//   name,
// }: {
//   companyId?: string;
//   name: string;
// }) => {
//   try {
//     const locale = localStorage.getItem(StorageConstant.LANGUAGE) || "vi";
//     const res = await Api<IGetProductListResponse>({
//       url: `/v2/categories`,
//       method: "GET",
//       params: {
//         companyId,
//         product_name: name,
//         locale,
//       },
//     });

//     if (res.data) {
//       return {
//         products:
//           res.data.map((p) => {
//             const category: any = p.category;

//             if (typeof category === "string") {
//               return {
//                 category: {
//                   _id: v4(),
//                   name: category,
//                 } as ICategory,
//                 products: p.products,
//               };
//             }

//             return p;
//           }) || [],
//       };
//     }

//     return undefined;
//   } catch (error) {
//     return undefined;
//   }
// };

const getProductsByCompanyId = async ({
  companyId,
  name,
  page = 1,
  pageSize = 10,
}: {
  companyId?: string;
  name: string;
  page: number;
  pageSize: number;
}) => {
  try {
    const res = await Api<IGetProductListResponse>({
      url: `/v1/companies/${companyId}/products`,
      method: "GET",
      params: {
        search: name,
        page,
        product_per_page: pageSize,
        status: ProductStatus.ACTIVE,
      },
    });

    if (res.data.statusCode === 200) {
      return res.data;
    }

    return undefined;
  } catch (error) {
    return undefined;
  }
};

const getDetails = async (id: string, saveStore = true) => {
  try {
    const locale = localStorage.getItem(StorageConstant.LANGUAGE) || "vi";

    const res = await Api<IGetProductDetailsResponse>({
      url: `/v1/products/${id}`,
      method: "GET",
      params: {
        locale,
      },
    });

    if (res.data.statusCode === 200) {
      saveStore &&
        setRecoil(ProductAtom.productDetails, {
          related: res.data.related,
          product: res.data.product,
        });
      return res.data;
    }

    return undefined;
  } catch (error) {
    return undefined;
  }
};

const getCategories = async () => {
  try {
    const res = await Api<IGetCategoriesResponse>({
      url: "/v1/categories",
      method: "GET",
      params: { offset: 0, limit: 1000 },
    });

    if (res.data.statusCode === 200) {
      setRecoil(ProductAtom.categories, res.data.categories || []);
      return res.data.categories;
    }

    return undefined;
  } catch (error) {
    return undefined;
  }
};

const getList = async (
  companyId: string,
  page = 1,
  pageSize = 20,
  search?: string,
  approval_status?: ApprovalStatus,
  sortBy: string = "createdAt",
  sortOrder: string = "desc"
) => {
  try {
    setRecoil(ProductAtom.products, {
      ...getRecoil(ProductAtom.products),
      loading: true,
    });

    const res = await Api<IGetProductsResponse>({
      url: `/v1/companies/${companyId}/products`,
      method: "GET",
      params: {
        search,
        status: ProductStatus.ACTIVE,
        approval_status,
        page,
        product_per_page: pageSize,
        sort_by: sortBy,
        sort_order: sortOrder,
      },
    });

    if (res.data.statusCode === 200) {
      setRecoil(ProductAtom.products, {
        ...getRecoil(ProductAtom.products),
        data: res.data.products,
        total: res.data.total,
        loading: false,
        page,
      });
      return res.data;
    }

    setRecoil(ProductAtom.products, {
      ...getRecoil(ProductAtom.products),
      loading: false,
    });
    return undefined;
  } catch (error) {
    setRecoil(ProductAtom.products, {
      ...getRecoil(ProductAtom.products),
      loading: false,
    });
    return undefined;
  }
};

const getProductList = async (
  page = 1,
  pageSize = 20,
  search?: string,
  category_id?: string
) => {
  try {
    const res = await Api<IGetProductsResponse>({
      url: "/v1/products",
      method: "GET",
      params: {
        page,
        pageSize,
        search,
        status: ProductStatus.ACTIVE,
        approval_status: ApprovalStatus.APPROVED,
        offset: (page - 1) * pageSize,
        limit: pageSize,
        categories: category_id,
      },
    });

    if (res.data.statusCode === 200) {
      return res.data;
    }

    return undefined;
  } catch (error) {
    return undefined;
  }
};

const importXlsx = async (companyId: string, data: FormData) => {
  try {
    const res = await Api<IGetProductsResponse>({
      url: `/v2/companies/${companyId}/products/import`,
      method: "POST",
      data,
    });

    return res;
  } catch (error) {
    return undefined;
  }
};

const deleteProduct = async (productId: string) => {
  try {
    const res = await Api<IUploadImageResponse>({
      url: `/v1/products/${productId}`,
      method: "DELETE",
    });

    if (res.statusCode === 200) {
      toast.success(i18n.t(Trans.product_deteled));
    }

    return res;
  } catch (error) {
    return null;
  }
};

const createProduct = async (companyId: string, data: Partial<IProduct>) => {
  try {
    const res = await Api<ICreateUpdateProductResponse>({
      url: `/v1/companies/${companyId}/products`,
      method: "POST",
      data,
    });

    if (res.data.statusCode === 200) {
      toast.success(i18n.t(Trans.product_created));
    }

    return res.data;
  } catch (error) {
    // toast.error(i18n.t(Trans.cannot_create_product));
    return null;
  }
};

const updateProduct = async (productId: string, data: Partial<IProduct>) => {
  try {
    const res = await Api<ICreateUpdateProductResponse>({
      url: `/v1/products/${productId}`,
      method: "PATCH",
      data,
    });

    if (res.data.statusCode === 200) {
      toast.success(i18n.t(Trans.product_updated));
    }

    return res.data;
  } catch (error) {
    // toast.error(i18n.t(Trans.cannot_update_product));

    return null;
  }
};

const uploadImages = async (companyId: string, formData: FormData) => {
  try {
    const res = await Api<IUploadImageResponse>({
      url: `/v1/companies/${companyId}/products/images`,
      method: "POST",
      data: formData,
    });

    if (res.statusCode === 200) {
      return res.data.files?.map((item) => item.url) || [];
    }

    return [];
  } catch (error) {
    return [];
  }
};

const ProductApi = {
  getProductsByCompanyId,
  getDetails,
  getCategories,
  getProductList,
  importXlsx,
  getList,
  uploadImages,
  deleteProduct,
  createProduct,
  updateProduct,
};

export default ProductApi;
