import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { AppScreens } from "~/router";
import Trans from "~/translation/translationKeys";
import LayoutContainer from "../LayoutContainer";

import "./Footer.less";

const Footer: React.FC = () => {
  const { t, i18n } = useTranslation();

  return (
    <LayoutContainer className="footer-bg" childrenClassName="footer">
      <h3 className="name">{t(Trans.okies_name)}</h3>

      <div className="footer-body">
        <div className="footer-left">
          <p className="footer-text">{t(Trans.tax_code)}: 0316819243</p>
          <p className="footer-text">{t(Trans.issue_date)}: 22/04/2021</p>
          <p className="footer-text">
            {t(Trans.address)}: {t(Trans.okies_address)}
          </p>
        </div>

        <div className="footer-right">
          <Link
            to={
              i18n.language === "en"
                ? AppScreens.PRIVACY_POLICY
                : AppScreens.PRIVACY_POLICY_VI
            }
            className="footer-text"
          >
            {t(Trans.privacy_policy)}
          </Link>
          <Link
            to={
              i18n.language === "en"
                ? AppScreens.TERMS_OF_USE
                : AppScreens.TERMS_OF_USE_VI
            }
            className="footer-text"
          >
            {t(Trans.terms_of_service)}
          </Link>
          <div className="footer-right footer-text">
            © myOKIES 2023. All rights reserved.
          </div>
        </div>
      </div>
    </LayoutContainer>
  );
};

export default Footer;
