import { ICartItem } from "~/services/cart";
import { IOrderItem } from "~/services/order";
import { ProductDiscountType } from "~/services/products";

const calculateDiscount = (
  price?: number,
  discount?: number,
  discountType?: number
) => {
  if (!price) {
    return 0;
  }

  let tmpDiscount = 0;

  if (discount) {
    if (discountType === ProductDiscountType.AMOUNTS) {
      tmpDiscount = discount;
    }

    if (discountType === ProductDiscountType.PERCENTAGES) {
      const discountAmout = (price / 100) * discount;
      tmpDiscount = discountAmout;
    }
  }

  return tmpDiscount;
};

const calculateProductPrice = (
  price?: number,
  discount?: number,
  discountType?: number
) => {
  if (!price) {
    return 0;
  }

  if (discount) {
    if (!discountType) {
      return price - discount;
    }

    if (discountType === ProductDiscountType.AMOUNTS) {
      return price - discount;
    }

    if (discountType === ProductDiscountType.PERCENTAGES) {
      const discountAmout = (price / 100) * discount;
      return price - discountAmout;
    }
  }

  return price;
};

const calculateOrderPrice = (items?: IOrderItem[]) => {
  let totalPrice = 0;
  items?.forEach((item) => {
    totalPrice += item.priceDiscounted * item.quantity;
  });

  return totalPrice;
};

const calculateCartItemsPrice = (items?: ICartItem[]) => {
  let totalPrice = 0;
  items?.forEach((item) => {
    const price = calculateProductPrice(
      item.product.price,
      item.product.discount,
      item.product.discountType
    );
    totalPrice += price * item.quantity;
  });

  return totalPrice;
};

const Pricing = {
  calculateProductPrice,
  calculateOrderPrice,
  calculateCartItemsPrice,
  calculateDiscount,
};

export default Pricing;
