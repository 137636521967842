import React from "react";
import { Modal as ModalAntd } from "antd";
import classNames from "classnames";

import { ModalProps } from "./Modal.type";
import Icon from "../Icon";

import "./Modal.less";

const Modal: React.FC<ModalProps> = (props) => {
  const { variant = "primary", className, ...rest } = props;

  return (
    <ModalAntd
      className={classNames(className, variant)}
      closeIcon={<Icon icon="cancel" color="#7F859F" />}
      centered
      {...rest}
    />
  );
};

export default Modal;
