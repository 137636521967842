const checkIsLink = (text: string) => {
  let link = false;
  ["http", "https", "ftp", "ftps"].forEach((item) => {
    if (text.includes(item)) {
      link = true;
    }
  });

  return link;
};

export default checkIsLink;
