import React from "react";
import classNames from "classnames";

import { LayoutContainerProps } from "./LayoutContainer.type";

import "./LayoutContainer.less";

const LayoutContainer: React.FC<LayoutContainerProps> = (props) => {
  const { style, children, childrenClassName, childrenStyle, className } =
    props;

  return (
    <div className={classNames("layout-container", className)} style={style}>
      <div
        className={classNames("layout-container-children", childrenClassName)}
        style={childrenStyle}
      >
        {children}
      </div>
    </div>
  );
};

export default LayoutContainer;
