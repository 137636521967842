const ACCESS_TOKEN = "ACCESS_TOKEN";
const LANGUAGE = "LANGUAGE";
const CUSTOMER_ID = "CUSTOMER_ID";

const StorageConstant = {
  ACCESS_TOKEN,
  LANGUAGE,
  CUSTOMER_ID,
};

export default StorageConstant;
