import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useRecoilValue, useResetRecoilState } from "recoil";

import { AppScreens } from "~/router";
import { paymentStatusModal } from "~/services/app";
import { CartAPI } from "~/services/cart";
import { OrderStatusEnum } from "~/services/order";
import Trans from "~/translation/translationKeys";
import Button from "../Button";
import Icon from "../Icon";
import Modal from "../Modal";

const PaymentSuccessModal: React.FC = () => {
  const nav = useNavigate();
  const { t } = useTranslation();
  const paymentStatus = useRecoilValue(paymentStatusModal);
  const resetModal = useResetRecoilState(paymentStatusModal);

  const getCartList = () => {
    CartAPI.getList();
  };

  const goToOrderDetail = () => {
    nav(`${AppScreens.ORDER}/${paymentStatus.orderId}`);
    resetModal();
    getCartList();
  };

  return (
    <Modal
      width={327}
      onCancel={() => {
        nav(`${AppScreens.STORES}/${paymentStatus.companyId}`);
        resetModal();
        getCartList();
      }}
      open={paymentStatus.open}
      footer={
        paymentStatus.payment ? (
          <div className="flex flex-col gap-y-4">
            <Button height={44} onClick={goToOrderDetail}>
              {t(Trans.check_order_detail)}
            </Button>
            <Button
              height={44}
              onClick={() => {
                nav(`${AppScreens.HOME}`);
                resetModal();
                getCartList();
              }}
            >
              {t(Trans.continue_shopping)}
            </Button>
          </div>
        ) : null
      }
    >
      <div className="flex flex-col items-center gap-y-4">
        <div
          className={classNames(
            "flex justify-center items-center bg-opacity-30 rounded-full w-[72px] h-[72px]",
            {
              "bg-[#20B352]":
                OrderStatusEnum.COMPLETED === paymentStatus.status,
              "bg-[#ff4343]":
                OrderStatusEnum.CANCELLED === paymentStatus.status,
            }
          )}
        >
          <Icon
            icon={paymentStatus.payment ? "money" : "close"}
            color={
              OrderStatusEnum.COMPLETED === paymentStatus.status
                ? "#20B252"
                : "#ff4343"
            }
            width={32}
            height={32}
          />
        </div>
        <div className="flex flex-col gap-x-1">
          <p className="mb-0 text-lg font-medium text-center text-white">
            {paymentStatus.message}
          </p>
          {!paymentStatus.payment && (
            <p className="text-sm text-[#878893] text-center mb-0">
              {t(Trans.one_pay_error_99)}
            </p>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default PaymentSuccessModal;
