import { IProduct } from "../products";
import { IStore } from "../store";
import { IUser } from "../user";

export interface ICartItem {
  product: IProduct;
  quantity: number;
  note: string;
  joinAt?: string | null;
}

export interface CartPayloadItem {
  id?: string;
  quantity: number;
  joinAt?: string | null;
}

export interface CartPayload {
  items?: Array<CartPayloadItem>;
  service_owner_id?: string;
  company_id?: string;
}

export enum ServiceMethodEnums {
  DELIVERY = 0,
  PICK_UP = 1,
  TABLE = 2,
}

export interface ICart {
  _id: string;
  customer: string;
  serviceOwner: IUser;
  company: IStore;
  createdAt: string;
  updatedAt: string;
  items: ICartItem[];
}

export interface IGetCartsResponse extends CommonResponse {
  data: {
    statusCode: number;
    carts: ICart[];
  };
}

export interface IUpdateCartResponse extends CommonResponse {
  data: {
    statusCode: number;
    message: string;
  };
}

export interface ICreateCartResponse extends CommonResponse {
  data: {
    statusCode: number;
    message: string;
    cart: ICart;
  };
}
