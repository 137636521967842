import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import classNames from "classnames";

import { CommonAtom } from "~/services/common";
import ChatInput from "./ChatInput";
import {
  Conversation,
  ConversationApi,
  ConversationAtom,
} from "~/services/conversation";
import Trans from "~/translation/translationKeys";
import Icon from "../Icon";
import ChatBody from "./ChatBody";
import { UserAtom, UserStatusEnum } from "~/services/user";
import { MessageApi, MessageAtom } from "~/services/message";

import "./ChatModal.less";

const ChatModal: React.FC = () => {
  const { t } = useTranslation();
  const user = useRecoilValue(UserAtom.userInfo);
  const openChatModal = useRecoilValue(CommonAtom.openChatModal);
  const resetOpenChatModal = useResetRecoilState(CommonAtom.openChatModal);
  const [loadingMessage, setLoadingMessage] = useState(false);
  const messages = useRecoilValue(MessageAtom.list);
  const [loadingLoadMore, setLoadingLoadMore] = useState(false);
  const [firstItemIndex, setFirstItemIndex] = useState(0);
  const ref = useRef<any>(null);
  const [conversationDetails, setConversationDetails] =
    useState<Conversation>();
  const setShowModalConfirm = useSetRecoilState(CommonAtom.showModalConfirm);
  const resetMessage = useResetRecoilState(MessageAtom.list);
  const setConversation = useSetRecoilState(ConversationAtom.list);
  const setTotalMessageUnread = useSetRecoilState(
    ConversationAtom.totalMessageUnread
  );
  const isStoreDisabled =
    conversationDetails?.user_2?.status !== UserStatusEnum.ACTIVE ||
    conversationDetails?.user_1?.status !== UserStatusEnum.ACTIVE;

  useEffect(() => {
    if (openChatModal.conversationId) {
      setLoadingMessage(true);

      const params = {
        page: messages.page,
        pageSize: messages.pageSize,
        offsetAdd: messages.offsetAdd,
        conversation: openChatModal.conversationId,
      };

      MessageApi.getList(params)
        .then((res) => {
          if (messages.page === 1) {
            setFirstItemIndex(res.total);
          } else {
            setFirstItemIndex((prev) => {
              if (prev - messages.pageSize < messages.pageSize) {
                return messages.pageSize;
              }

              return prev - messages.pageSize;
            });
          }
        })
        .finally(() => {
          setLoadingMessage(false);
          setLoadingLoadMore(false);
        });
    }
  }, [
    messages.page,
    messages.pageSize,
    messages.offsetAdd,
    openChatModal.conversationId,
  ]);

  useEffect(() => {
    if (openChatModal.conversationId) {
      ConversationApi.getDetails(openChatModal.conversationId).then((data) => {
        if (data) {
          setConversationDetails(data);
        }

        if (data?.un_read_count) {
          MessageApi.markAsRead(data._id).then(() => {
            setConversation((prevConversation) => ({
              ...prevConversation,
              data: prevConversation.data.map((item) => {
                if (item._id === data._id) {
                  return { ...item, un_read_count: 0 };
                }

                return item;
              }),
            }));
          });
          setTotalMessageUnread(
            (currentTotalMessageUnread) =>
              currentTotalMessageUnread - data.un_read_count
          );
        }
      });
    }

    return () => {
      resetMessage();
    };
  }, [
    openChatModal.conversationId,
    resetMessage,
    setConversation,
    setTotalMessageUnread,
  ]);

  const scrollToBottom = () => {
    ref.current?.scrollToIndex(messages.total + messages.offsetAddNotLoad);
  };

  const handleToggleConversation = async () => {
    if (openChatModal?.conversationId) {
      ConversationApi.toggleConversation(openChatModal.conversationId).then(
        (data) => {
          if (data) {
            setConversation((prevConversation) => ({
              ...prevConversation,
              data: prevConversation.data.map((item) => {
                if (item._id === data._id) {
                  return data;
                }
                return item;
              }),
            }));
            setConversationDetails(data);
          }
        }
      );
    }
  };

  const checkIsBlock = () => {
    const isMine = conversationDetails?.user_1?._id === user?._id;

    if (
      !conversationDetails?.is_user_1_blocked &&
      !conversationDetails?.is_user_2_blocked
    ) {
      return true;
    }

    if (isMine && conversationDetails?.is_user_1_blocked) {
      return false;
    }

    if (!isMine && conversationDetails?.is_user_2_blocked) {
      return false;
    }

    return true;
  };

  const renderBlock = () => {
    const isMine = conversationDetails?.user_1?._id === user?._id;

    if (
      !conversationDetails?.is_user_1_blocked &&
      !conversationDetails?.is_user_2_blocked &&
      !isStoreDisabled
    ) {
      return null;
    }

    if (isStoreDisabled) {
      return t(Trans.the_store_has_been_disabled);
    }

    if (isMine && conversationDetails?.is_user_1_blocked) {
      return t(Trans.you_have_blocked_this_user);
    }

    if (!isMine && conversationDetails?.is_user_2_blocked) {
      return t(Trans.you_have_blocked_this_user);
    }

    return t(Trans.you_have_been_blocked_by_this_user);
  };

  return (
    <div
      className={classNames("chat-modal", {
        active: openChatModal.open,
      })}
    >
      <div className="chat-modal-header">
        <h2 className="left line-clamp-1">
          {(conversationDetails?.user_1?._id === user?._id
            ? conversationDetails?.user_2?.name
            : conversationDetails?.user_1?.name) || "-"}
        </h2>

        <div className="right">
          <p
            className="action"
            onClick={() =>
              setShowModalConfirm({
                open: true,
                onConfirm: handleToggleConversation,
                title: checkIsBlock()
                  ? t(Trans.do_you_want_to_block_this_conversation)
                  : t(Trans.do_you_want_to_unblock_this_conversation),
                description: checkIsBlock()
                  ? t(
                      Trans.if_you_block_you_will_no_longer_be_able_to_message_this_person
                    )
                  : t(Trans.if_you_unblock_you_can_message_this_person),
              })
            }
          >
            {checkIsBlock() ? t(Trans.block) : t(Trans.unblock)}
          </p>

          <Icon
            icon="close"
            color="#A3A9B3"
            className="cursor-pointer"
            onClick={resetOpenChatModal}
          />
        </div>
      </div>

      <ChatBody
        firstItemIndex={firstItemIndex}
        loadingMessage={loadingMessage}
        loadingLoadMore={loadingLoadMore}
        ref={ref}
        offsetAdd={messages.offsetAddNotLoad}
        setLoadingLoadMore={setLoadingLoadMore}
        conversationDetails={conversationDetails}
      />

      {!conversationDetails?.is_user_1_blocked &&
      !conversationDetails?.is_user_2_blocked &&
      !isStoreDisabled ? (
        <ChatInput
          loadingMessage={loadingMessage}
          scrollToBottom={scrollToBottom}
        />
      ) : (
        <div className="block">{renderBlock()}</div>
      )}
    </div>
  );
};

export default ChatModal;
