import { FC } from "react";
import IcoMoon, { IconProps } from "react-icomoon";

import iconSet from "~/components/Icon/selection.json";

const IconSet: FC<IconProps> = (props) => (
  <IcoMoon iconSet={iconSet} {...props} />
);

const Icon: FC<IconProps> = ({
  size = 24,
  color = "#FAFAFA",
  style,
  width = 24,
  height = 24,
  ...rest
}) => {
  return (
    <IconSet
      size={size}
      color={color}
      style={{ width, height, ...style }}
      {...rest}
    />
  );
};

export default Icon;
