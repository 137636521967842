const ja = {
  translation: {
    stores: "Stores",
    stores_desc:
      "Discover our top-quality restaurants, shops, art boutiques and many more",
    there_are_no_stores_yet: "There are no stores yet. Try to come back later.",
    stores_list: "Store listings",
    search_stores:
      "Workshops, Restaurants, Bars, Coffee shops, Bakery, more...",
    no_stores: "No stores",
    no_order: "No order",
    no_reservation: "No reservation",
    search: "Search",
    sign_in: "Sign in",
    sign_up: "Sign up",
    log_out: "Log out",
    cart: "Cart",
    account: "Account",
    edit_information: "Edit Information",
    order_history: "Order History",
    reservation_history: "Reservation History",
    change_password: "Change password",
    english: "English",
    vietnamese: "Vietnamese",
    home_desc:
      "Discover our top quality restaurants, retail stores and creative boutiques. Experience a taste of mindful shopping composed of inspiring design and the comfort of modern technology.",
    discover_now: "Discover Now",
    contact: "Contact",
    app: "App",
    legal_and_privacy: "Legal & Privacy",
    there_number_results_for: "There are {{total}} results for",
    password: "Password",
    old_password: "Old password",
    new_password: "New password",
    confirm_name: "Confirm {{text}}",
    save: "Save",
    show_detail: "Show detail",
    hide_detail: "Hide detail",
    send_reservation: "Send Reservation",
    order_status: "Order Status",
    status: "Status",
    order_desc: "Your order will be ready by",
    store_info: "Store Info",
    product_info: "Product Info",
    information: "Information",
    delete_account: "Delete Account",
    cancel: "Cancel",
    your_account_will_be_deleted_permanently:
      "Your account will be deleted permanently",
    discover_store: "Discover Store",
    home: "Home",
    book_a_table: "Book a Table",
    clear_all: "Clear All",
    can_not_find_store: "Can't find store",
    can_not_find_product: "Can't find product",
    no_results_try_another_keyword: "No results. Try another keyword.",
    reservation_information: "Reservation Information",
    detail_information: "Detail Information",
    reservation_date: "Reservation date",
    reservation_time: "Reservation time",
    time: "Time",
    total_guest: "Total Guest",
    special_request_not_required: "Special Request ( not required )",
    placeholder_special_request_not_required:
      "Ex: special occasion, Table location, etc.",
    make_a_reservation: "Make a Reservation",
    restaurant_information: "Restaurant Information",
    as_seller: "as Business",
    do_not_have_an_account: "Don't have an account?",
    remember_password: "Remember password",
    forget_password: "Forget password",
    forgot_password: "Forgot password",
    name_at_least_6_characters: "The {{text}} must be at least 6 characters",
    confirm_password_error: "Please input the same {{text}} above",
    agree_width: "Sign Up an account with my-outie means you agree with our",
    terms_of_service: "Terms of service",
    privacy_policy: "Privacy policy",
    already_have_an_account: "Already have an account?",
    set: "Set",
    reset_password: "Reset Password",
    enter_your_otp: "Enter your OTP",
    sent_otp: "We have sent a one-time password (OTP)",
    to_your: "to your {{text}}",
    resend_now: "Resend now",
    did_not_receive_otp: "Didn't receive OTP",
    name_unsuccessful: "{{text}} UnSuccessful",
    not_permissions: "Not permissions",
    please_login_again_in_a_few_minutes: "Please login again in a few minutes",
    please_verify_your_account: "Please verify your account",
    booking: "Booking",
    back_homepage: "Back HomePage",
    a_reservation_confirmation_is_sent_to_your_email:
      "A Reservation Confirmation is sent to your email",
    page_not_found: "Page Not Found",
    no_data: "No data",
    please_choose_time_greater_than_current_time:
      "Please choose a time greater than the current time",
    about_us: "About Us",
    about_us_title: "The Future of POS Systems and Cashiers",
    about_us_content: "Transform your business operations with ",
    about_us_content_2:
      "- the all-in-one automation solution that streamlines the ordering and payment process. Our cutting-edge technology, using digital QR codes for menus, services, and products, delivers an exceptional customer service experience, improves efficiency, and reduce operation costs. Take your business to the next level with ",
    register_business_account: "Register Business Account",
    about_us_address:
      "Vincom Center Dong Khoi, Floor 17-11, 72 Le Thanh Ton, Ben Nghe Ward, District 1, HCMC, Vietnam",
    scan_qr_code_to_order_and_pay: "Scan QR Code to Order and Pay",
    online_and_offline_sales_in_one_app: "Online and Offline Sales in One App",
    unique_luxury_design: "Unique Luxury Design",
    modern_automation_technology: "Modern Automation Technology",
    multiple_payment_options: "Multiple Payment Options",
    online_operation_management: "Online Operation Management",
    fast_order_and_secure_payment: "Fast Order and Secure Payment",
    customer_loyalty_program: "Customer Loyalty Program",
    digital_qr_menu_or_product_list: "Digital QR Menu or Product List",
    more_customers_more_profit: "More Customers. More Profit",
    dedicate_for_hight_end_customer_shopping_and_dining_experience:
      "Dedicate for High-End Customer Shopping and Dining Experience",
    reduce_operation_cost: "Reduce Operation Cost",
    access_24_7_with_auto_sales_reports: "Access 24/7 with Auto Sales Reports",
    improve_customer_service_experience: "Improve Customer Service Experience",
    e_wallets_credit_debit_cards_and_cash_option:
      "E-Wallets, Credit/Debit Cards, and Cash Option.",
    reward_your_customers_width_point_redeemption_discount_price_and_many_more:
      "Reward Your Customers with Point Redeemption, Discount Price and many more.",
    main_features: "Main Features",
    business_application: "Business Application",
    food_and_beverage_businesses: "Food and Beverage Businesses",
    food_and_beverage_businesses_content:
      "From restaurants to coffee shops, process orders quickly with my-outie - QR Menu. Simply scan QR Code to order and pay direclty on the phone. Manage your business in real time, free auto sales reports daily, monthly and yearly. Minimize your operation cost, and improve your customer service exprience.",
    retail_business: "Retail Business",
    retail_business_content:
      "Set your business apart from others with our technology service for online and in-store sales by purchasing through QR Code with the production information. Easily operate and manage your store entirely online, access 24/7 and accept all types of payment options from e-wallet to all debit/credit cards with my-outie. Improve your customer shopping exprience, simply scan QR code to order, pay directly on app and receive the orders immediately.",
    service_businesses: "Service Businesses",
    service_businesses_content:
      "Quick and easy to order and pay for your service with my-outie by scanning QR Code for Service List. Manage all aspects of your business completely online, access your management details from anywhere and anytime. Improve your customer service experience simply with a scannable QR code to order and pay directly on their phone and schedule appointments for your business with just a few clicks.",
    restaurant: "Restaurant",
    coffee_shop: "Coffee Shop",
    bar_lounge: "Bar, Lounge",
    hotel_resort_in_room_dining_service: "Hotel/Resort In-Room Dining Service",
    dessert_ice_cream_bakery_shop: "Dessert, Ice Cream, Bakery Shop",
    home_decor_furniture: "Home Decor, Furniture",
    wine_spirit_and_cigar: "Wine, Spirit and Cigar",
    flower_boutique_shop: "Flower Boutique Shop",
    airport_duty_free: "Airport Duty Free",
    book_store: "Bookstore",
    cosmetic: "Cosmetic",
    art_gallery: "Art Gallery",
    beauty_salon_hair_spa_nails_massage:
      "Beauty Salon - Hair, Spa, Nails, Massage",
    hotel_resort_in_room_spa_service: "Hotel/Resort In-Room Spa Service",
    gym_membership: "Gym Membership",
    event_ticket: "Event Ticket",
    health_care_clinic_hospital: "Health Care, Clinic, Hospital",
    vet_service: "Vet Service",
    casino_gambling_chips: "Casino Gambling Chips",
    our_service_is_currently_available_to:
      "Our service is currently available to:",
    tax_code: "Tax code",
    user_not_found: "User not found",
    are_you_sure_you_want_to_logout: "Are you sure you want to logout?",
    are_you_sure_you_want_to_cancel_this_reservation:
      "Are you sure you want to cancel this reservation?",
    back: "Back",
    welcome_back: "Welcome back, {{name}}",
    draft: "Draft",
    confirmed: "Confirmed",
    special_request: "Special Request",
    get_pdf_reservation: "Get PDF Reservation",
    cancel_reservation: "Cancel Reservation",
    business_brochure: "Business Brochure",
    eliminate_missing_order: "Eliminate Missing Order",
    orders_are_recorded_by_myokies_system_directly_from_customer_to_business_owner_management_dashboard:
      "Orders are recorded by my-outie system directly from customer to bussiness owner management dashboard",
    inflight_dining: "Inflight Dining",
    verify_account:
      "Please verify your account, the verify link has been sent to your email!",
    your_account_has_been_verified: "Your account has been verified",
    other: "Other",
    product_minimum_price: "Product minimum price is 20,000 VND",
    and: "and",
    reservation_created:
      "Thank you for your reservation. Your request is pending confirmation. Store will review your reservation and send you an email confirmation as soon as possible.",
    banner_chart_title:
      "<span class='important'>my-outie</span> vs. POS system Comparison Chart",

    please_choose: "{{text}}を選択してください",
    category: "カテゴリー",
    search_products: "商品を検索する",
    reservation: "予約",
    open: "営業中",
    closed: "閉店",
    add_to_cart: "カートに追加する",
    name_successful: "{{text}}が成功しました",
    in_stock: "在庫あり",
    out_of_stock: "在庫切れ",
    see_more: "もっと見る",
    see_less: "もっと少なく見る",
    back_to_products: "店舗に戻る",
    you_may_also_like: "あなたはこれも好きかもしれません",
    your_cart: "カート",
    your_cart_is_empty: "カートに商品がありません",
    please_choose_company: "お支払いする会社を選択してください",
    continue: "続行",
    back_to_choose: "選択に戻る",
    continue_to_check_out: "チェックアウトを続ける",
    remove: "削除",
    note: "ノート",
    full_name: "フルネーム",
    please_enter_your: "{{text}}を入力してください",
    email_address: "メールアドレス",
    phone_number: "電話番号",
    service_method: "サービス方法",
    payment_method: "支払方法",
    location: "テーブル番号",
    address: "住所",
    credit_debit_card: "クレジット/デビットカード",
    atm_cart: "ATMカード",
    e_wallet: "電子ウォレット",
    make_payment: "支払いをする",
    please_make_payment_at_pick_up_station:
      "受け取りステーションでお支払いください",
    back_to_your_cart: "カートに戻る",
    payment_failed: "支払いが失敗しました",
    payment_successful: "支払いが成功しました",
    total_price: "合計金額",
    one_pay_error_99: "ユーザーがトランザクションをキャンセルしました。",
    check_order_detail: "注文詳細を確認する",
    continue_shopping: "買い物を続ける",
    order: "注文",
    order_completed: "ご注文ありがとうございます。",
    store_name: "店舗名",
    store_address: "店舗住所",
    store_email: "店舗メールアドレス",
    store_phone_number: "店舗電話番号",
    order_information: "注文情報",
    order_no: "注文番号",
    date: "日付",
    store_information: "店舗情報",
    payment_information: "支払情報",
    delivery_option: "配送オプション",
    delivery_address: "配送先住所",
    payment_status: "支払い状況",
    customer_information: "顧客情報",
    name: "名前",
    email: "メール",
    back_to_home: "ホームに戻る",
    pending: "保留中",
    paid: "支払済み",
    cancelled: "キャンセル済み",
    table: "店内",
    pick_up: "持ち帰り",
    delivery: "デリバリー",
    cash: "現金",
    processing: "処理中",
    change_language: "言語を変更する",
    choose_a_language: "言語を選択する",
    send_receipt: "レシートを送信する",
    the_store_is_closed: "店舗は閉店しています。",
    product_information: "商品情報",
    there_are_no_products_to_pay_for: "支払う商品がありません。",
    maximum_name_is_60_characters: "名前は最大60文字です。",
    your_name_is_not_valid: "{{text}}は無効です。",
  },
};

export default ja;
