import React from "react";
import { useTranslation } from "react-i18next";
import ReactLoading from "react-loading";

import Trans from "~/translation/translationKeys";

import "./Loading.less";

const Loading: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="loading">
      <ReactLoading type="spinningBubbles" />
      <span className="text">{t(Trans.processing)}</span>
    </div>
  );
};

export default Loading;
