import React from "react";
import { v4 } from "uuid";

import { IRoute, LayoutEnum } from "~/types/route";
import AppScreens from "./router.type";

const AppRoutes: Array<IRoute> = [
  {
    key: v4(),
    slug: AppScreens.SEARCH,
    component: React.lazy(() => import("~/screens/Search")),
    authRequire: false,
    layout: LayoutEnum.DEFAULT,
  },
  {
    key: v4(),
    slug: AppScreens.SIGN_IN,
    component: React.lazy(() => import("~/screens/SignIn")),
    authRequire: false,
    layout: LayoutEnum.AUTH,
  },
  {
    key: v4(),
    slug: AppScreens.SIGN_UP,
    component: React.lazy(() => import("~/screens/SignUp")),
    authRequire: false,
    layout: LayoutEnum.AUTH,
  },
  {
    key: v4(),
    slug: AppScreens.FORGOT_PASSWORD,
    component: React.lazy(() => import("~/screens/ForgotPassword")),
    authRequire: false,
    layout: LayoutEnum.FORGOT_PASSWORD,
  },
  {
    key: v4(),
    slug: AppScreens.RESET_PASSWORD,
    component: React.lazy(() => import("~/screens/ResetPassword")),
    authRequire: false,
    layout: LayoutEnum.FORGOT_PASSWORD,
  },
  {
    key: v4(),
    slug: AppScreens.NOT_FOUND,
    component: React.lazy(() => import("~/screens/PageNotFound")),
    authRequire: false,
    layout: LayoutEnum.DEFAULT,
  },
  {
    key: v4(),
    slug: AppScreens.HOME,
    authRequire: false,
    layout: LayoutEnum.DEFAULT,
    component: React.lazy(() => import("~/screens/Products")),
  },
  // {
  //   key: v4(),
  //   slug: `${AppScreens.STORES}/:id`,
  //   component: React.lazy(() => import("~/screens/StoreDetails")),
  //   authRequire: false,
  //   layout: LayoutEnum.DEFAULT,
  // },
  {
    key: v4(),
    slug: `${AppScreens.PRODUCT}/:id`,
    component: React.lazy(() => import("~/screens/ProductDetails")),
    authRequire: false,
    layout: LayoutEnum.DEFAULT,
  },
  {
    key: v4(),
    slug: AppScreens.PROFILE,
    path: AppScreens.PROFILE,
    authRequire: true,
    layout: LayoutEnum.DEFAULT,
    children: [
      {
        key: v4(),
        component: React.lazy(() => import("~/screens/Profile/Information")),
        isIndex: true,
        authRequire: true,
        layout: LayoutEnum.DEFAULT,
      },
      {
        key: v4(),
        slug: "product-management",
        component: React.lazy(
          () => import("~/screens/Profile/ProductManagement")
        ),
        authRequire: true,
        layout: LayoutEnum.DEFAULT,
      },
      {
        key: v4(),
        slug: "chat",
        component: React.lazy(() => import("~/screens/Profile/Conversations")),
        authRequire: true,
        layout: LayoutEnum.DEFAULT,
      },
      {
        key: v4(),
        slug: "order-history",
        component: React.lazy(() => import("~/screens/Profile/OrderHistory")),
        authRequire: true,
        layout: LayoutEnum.DEFAULT,
      },
      {
        key: v4(),
        slug: "change-password",
        component: React.lazy(() => import("~/screens/Profile/ChangePassword")),
        authRequire: true,
        layout: LayoutEnum.DEFAULT,
      },
      {
        key: v4(),
        slug: "reservation-history",
        component: React.lazy(
          () => import("~/screens/Profile/ReservationHistory")
        ),
        authRequire: true,
        layout: LayoutEnum.DEFAULT,
      },
    ],
  },
  {
    key: v4(),
    slug: AppScreens.PRIVACY_POLICY,
    component: React.lazy(
      () => import("~/screens/PrivacyPolicy/PrivacyPolicyEN")
    ),
    authRequire: false,
    layout: LayoutEnum.PRIVACY,
  },
  {
    key: v4(),
    slug: AppScreens.PRIVACY_POLICY_VI,
    component: React.lazy(
      () => import("~/screens/PrivacyPolicy/PrivacyPolicyVI")
    ),
    authRequire: false,
    layout: LayoutEnum.PRIVACY,
  },
  {
    key: v4(),
    slug: AppScreens.TERMS_OF_USE,
    component: React.lazy(() => import("~/screens/TermsOfUse/TermsOfUseEN")),
    authRequire: false,
    layout: LayoutEnum.PRIVACY,
  },
  {
    key: v4(),
    slug: AppScreens.TERMS_OF_USE_VI,
    component: React.lazy(() => import("~/screens/TermsOfUse/TermsOfUseVI")),
    authRequire: false,
    layout: LayoutEnum.PRIVACY,
  },
  {
    key: v4(),
    slug: `${AppScreens.RESERVATION}/:id`,
    component: React.lazy(() => import("~/screens/ReservationDetails")),
    authRequire: false,
    layout: LayoutEnum.DEFAULT,
  },
  {
    key: v4(),
    slug: AppScreens.CART,
    component: React.lazy(() => import("~/screens/Cart")),
    authRequire: false,
    layout: LayoutEnum.DEFAULT,
  },
  {
    key: v4(),
    slug: `${AppScreens.ORDER}/:id`,
    component: React.lazy(() => import("~/screens/OrderDetail")),
    authRequire: false,
    layout: LayoutEnum.DEFAULT,
  },
  {
    key: v4(),
    slug: AppScreens.ABOUT_US,
    component: React.lazy(() => import("~/screens/AboutUs")),
    authRequire: false,
    layout: LayoutEnum.DEFAULT,
  },
  {
    key: v4(),
    slug: `${AppScreens.MENUS}/:id`,
    component: React.lazy(() => import("~/screens/MenuDetails")),
    authRequire: false,
    layout: LayoutEnum.DEFAULT,
  },
];

export default AppRoutes;
