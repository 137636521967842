import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en from "./languages/en";
import vi from "./languages/vi";
import af from "./languages/af";
import ak from "./languages/ak";
import sq from "./languages/sq";
import am from "./languages/am";
import ar from "./languages/ar";
import hy from "./languages/hy";
import as from "./languages/as";
import ay from "./languages/ay";
import az from "./languages/az";
import bm from "./languages/bm";
import eu from "./languages/eu";
import be from "./languages/be";
import bn from "./languages/bn";
import bho from "./languages/bho";
import bs from "./languages/bs";
import bg from "./languages/bg";
import ca from "./languages/ca";
import ceb from "./languages/ceb";
import ny from "./languages/ny";
import zh from "./languages/zh";
import zh_TW from "./languages/zh-TW";
import co from "./languages/co";
import hr from "./languages/hr";
import cs from "./languages/cs";
import da from "./languages/da";
import dv from "./languages/dv";
import doi from "./languages/doi";
import nl from "./languages/nl";
import eo from "./languages/eo";
import et from "./languages/et";
import ee from "./languages/ee";
import tl from "./languages/tl";
import fi from "./languages/fi";
import fr from "./languages/fr";
import fy from "./languages/fy";
import gl from "./languages/gl";
import lg from "./languages/lg";
import ka from "./languages/ka";
import de from "./languages/de";
import gom from "./languages/gom";
import el from "./languages/el";
import gn from "./languages/gn";
import gu from "./languages/gu";
import ht from "./languages/ht";
import ha from "./languages/ha";
import haw from "./languages/haw";
import iw from "./languages/iw";
import hi from "./languages/hi";
import hmn from "./languages/hmn";
import hu from "./languages/hu";
import is from "./languages/is";
import ig from "./languages/ig";
import ilo from "./languages/ilo";
import id from "./languages/id";
import ga from "./languages/ga";
import it from "./languages/it";
import ja from "./languages/ja";
import jw from "./languages/jw";
import kn from "./languages/kn";
import kk from "./languages/kk";
import km from "./languages/km";
import rw from "./languages/rw";
import ko from "./languages/ko";
import kri from "./languages/kri";
import ku from "./languages/ku";
import ckb from "./languages/ckb";
import ky from "./languages/ky";
import lo from "./languages/lo";
import la from "./languages/la";
import lv from "./languages/lv";
import ln from "./languages/ln";
import lt from "./languages/lt";
import lb from "./languages/lb";
import mk from "./languages/mk";
import mai from "./languages/mai";
import mg from "./languages/mg";
import ms from "./languages/ms";
import ml from "./languages/ml";
import mt from "./languages/mt";
import mni_Mtei from "./languages/mni-Mtei";
import mi from "./languages/mi";
import mr from "./languages/mr";
import lus from "./languages/lus";
import mn from "./languages/mn";
import my from "./languages/my";
import ne from "./languages/ne";
import nso from "./languages/nso";
import no from "./languages/no";
import or from "./languages/or";
import om from "./languages/om";
import ps from "./languages/ps";
import fa from "./languages/fa";
import pl from "./languages/pl";
import pt from "./languages/pt";
import pa from "./languages/pa";
import qu from "./languages/qu";
import ro from "./languages/ro";
import ru from "./languages/ru";
import sm from "./languages/sm";
import sa from "./languages/sa";
import gd from "./languages/gd";
import sr from "./languages/sr";
import st from "./languages/st";
import sn from "./languages/sn";
import sd from "./languages/sd";
import si from "./languages/si";
import sk from "./languages/sk";
import sl from "./languages/sl";
import so from "./languages/so";
import es from "./languages/es";
import su from "./languages/su";
import sw from "./languages/sw";
import sv from "./languages/sv";
import tg from "./languages/tg";
import ta from "./languages/ta";
import tt from "./languages/tt";
import te from "./languages/te";
import th from "./languages/th";
import ti from "./languages/ti";
import ts from "./languages/ts";
import tr from "./languages/tr";
import tk from "./languages/tk";
import uk from "./languages/uk";
import ur from "./languages/ur";
import ug from "./languages/ug";
import uz from "./languages/uz";
import cy from "./languages/cy";
import xh from "./languages/xh";
import yi from "./languages/yi";
import yo from "./languages/yo";
import zu from "./languages/zu";
import he from "./languages/he";
import jv from "./languages/jv";
import zh_CN from "./languages/zh-CN";

i18n.use(initReactI18next).init({
  resources: {
    en,
    vi,
    af,
    ak,
    sq,
    am,
    ar,
    hy,
    as,
    ay,
    az,
    bm,
    eu,
    be,
    bn,
    bho,
    bs,
    bg,
    ca,
    ceb,
    ny,
    zh,
    "zh-TW": zh_TW,
    co,
    hr,
    cs,
    da,
    dv,
    doi,
    nl,
    eo,
    et,
    ee,
    tl,
    fi,
    fr,
    fy,
    gl,
    lg,
    ka,
    de,
    gom,
    el,
    gn,
    gu,
    ht,
    ha,
    haw,
    iw,
    hi,
    hmn,
    hu,
    is,
    ig,
    ilo,
    id,
    ga,
    it,
    ja,
    jw,
    kn,
    kk,
    km,
    rw,
    ko,
    kri,
    ku,
    ckb,
    ky,
    lo,
    la,
    lv,
    ln,
    lt,
    lb,
    mk,
    mai,
    mg,
    ms,
    ml,
    mt,
    "mni-Mtei": mni_Mtei,
    mi,
    mr,
    lus,
    mn,
    my,
    ne,
    nso,
    no,
    or,
    om,
    ps,
    fa,
    pl,
    pt,
    pa,
    qu,
    ro,
    ru,
    sm,
    sa,
    gd,
    sr,
    st,
    sn,
    sd,
    si,
    sk,
    sl,
    so,
    es,
    su,
    sw,
    sv,
    tg,
    ta,
    tt,
    te,
    th,
    ti,
    ts,
    tr,
    tk,
    uk,
    ur,
    ug,
    uz,
    cy,
    xh,
    yi,
    yo,
    zu,
    he,
    jv,
    "zh-CN": zh_CN,
  },
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
});

export default i18n;
