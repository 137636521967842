import { setRecoil, getRecoil } from "recoil-nexus";

import Api from "../api";
import {
  GetConversationResponse,
  GetConversationsParams,
  GetConversationsResponse,
} from "./conversation.type";
import ConversationAtom from "./conversation.atom";

const getList = async ({
  page = 1,
  pageSize = 10,
  search,
  saveStore = true,
}: GetConversationsParams) => {
  try {
    const offset = (page - 1) * pageSize;

    const res = await Api<GetConversationsResponse>({
      url: "/v1/conversations",
      method: "GET",
      params: {
        offset,
        limit: pageSize,
        search_company: search,
      },
    });

    const currentValues = getRecoil(ConversationAtom.list);

    saveStore &&
      setRecoil(ConversationAtom.list, {
        ...currentValues,
        data: res.data,
        total: res.total || 0,
      });

    return {
      data: res.data || [],
      total: res.total || 0,
      totalMessageUnread: res.total_un_read_count || 0,
    };
  } catch (error) {
    return {
      data: [],
      total: 0,
      totalMessageUnread: 0,
    };
  }
};

const getDetails = async (conversationId: string) => {
  try {
    const res = await Api<GetConversationResponse>({
      url: `/v1/conversations/${conversationId}`,
      method: "GET",
    });
    if (res.statusCode === 200) {
      return res.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

const toggleConversation = async (conversationId: string) => {
  try {
    const res = await Api<GetConversationResponse>({
      url: `/v1/conversations/toggle-block/${conversationId}`,
      method: "PATCH",
    });

    return res.data;
  } catch (error) {
    return null;
  }
};

const createConversation = async (data: {
  order_id?: string;
  product_id?: string;
}) => {
  try {
    const res = await Api<GetConversationResponse>({
      url: "/v1/conversations",
      method: "POST",
      data,
    });

    if (res.statusCode === 200) {
      return res.data;
    }

    return null;
  } catch (error) {
    return null;
  }
};

const ConversationApi = {
  getList,
  getDetails,
  toggleConversation,
  createConversation,
};

export default ConversationApi;
