import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { RecoilRoot } from "recoil";
import RecoilNexus from "recoil-nexus";
import { ConfigProvider } from "antd";
import en from "antd/lib/locale/en_US";
import vi from "antd/lib/locale/vi_VN";
import moment from "moment";
import TimeAgoEn from "javascript-time-ago/locale/en.json";
import TimeAgo from "javascript-time-ago";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import i18n from "./translation";
import PaymentSuccessModal from "./components/PaymentSuccessModal";

import "~/styles/index.less";
import "moment/locale/vi";

// Socket Listen
import "~/services/socket";

moment.locale("vi");
TimeAgo.addDefaultLocale(TimeAgoEn);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <RecoilRoot>
        <RecoilNexus />
        <ConfigProvider locale={i18n.language === "en" ? en : vi}>
          <App />
          <PaymentSuccessModal />
        </ConfigProvider>
        <ToastContainer
          position="top-center"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover
          // theme="colored"
          theme="colored"
        />
      </RecoilRoot>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
