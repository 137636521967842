import React, { useEffect } from "react";
import classNames from "classnames";
import { useRecoilValue } from "recoil";
import { useLocation, useNavigate } from "react-router-dom";

import Footer from "../Footer";
import Header from "~/layouts/Header";
import { LayoutProps } from "./Layout.type";
import { UserAtom, UserStatusEnum } from "~/services/user";
import { AppScreens } from "~/router";
import HeaderPrivacy from "~/layouts/HeaderPrivacy";
import { LayoutEnum } from "~/types/route";

import "./Layout.less";

const Layout: React.FC<LayoutProps> = ({
  children,
  className,
  layout = LayoutEnum.DEFAULT,
}) => {
  const user = useRecoilValue(UserAtom.userInfo);
  const nav = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (
      layout === LayoutEnum.AUTH &&
      user &&
      user.status === UserStatusEnum.ACTIVE
    ) {
      nav(AppScreens.HOME);
    }
  }, [layout, nav, user]);

  const renderHeader = () => {
    switch (layout) {
      case LayoutEnum.PRIVACY: {
        return <HeaderPrivacy />;
      }

      default: {
        return <Header />;
      }
    }
  };

  const renderFooter = () => {
    switch (layout) {
      case LayoutEnum.DEFAULT: {
        return <Footer />;
      }
      // case LayoutEnum.AUTH: {
      //   return <FooterAuth />;
      // }

      default: {
        return <React.Fragment></React.Fragment>;
      }
    }
  };

  return (
    <div className="layout">
      {renderHeader()}
      <div
        className={classNames("layout-children", className, {
          "flex justify-center items-center":
            window.innerHeight > 768 &&
            location.pathname === AppScreens.SIGN_UP,
        })}
      >
        {children}
      </div>
      {renderFooter()}
    </div>
  );
};

export default Layout;
