import { CountryCodeEnum } from "~/components/InputPhone/InputPhone.type";
import { ServiceMethodEnums } from "../cart";
import { PaymentMethodType } from "../payment/payment.types";
import { IProduct } from "../products";
import { IStore } from "../store";
import { IUser } from "../user";

export enum PaymentStatusEnum {
  PENDING = "Pending",
  PAID = "Paid",
}

export interface CreateOrderProps {
  cartId?: string;
  paymentMethod: PaymentMethodType;
  customer_info: {
    name?: string;
    country_code: CountryCodeEnum;
    email?: string;
    phone_number: string;
  };
  serviceMethod: {
    method: ServiceMethodEnums;
    address?: string;
    table?: string;
  };
}

export interface IOrderItem {
  _id: string;
  discount: number;
  price: number;
  priceDiscounted: number;
  product: IProduct;
  quantity: number;
  note?: string;
  joinAt?: string;
}

export enum OrderStatusEnum {
  DRAFT = 0,
  PENDING = 1,
  COMPLETED = 2,
  CANCELLED = 3,
}

export interface IOrder {
  _id: string;
  orderNumber: string;
  createdAt: string;
  paymentMethod: PaymentMethodType;
  serviceOwner: IUser;
  updatedAt: string;
  status: OrderStatusEnum;

  products: {
    id: string;
    name: string;
    quantity: number;
    price: number;
  }[];
  payment: {
    method: string;
    status: PaymentStatusEnum;
  };
  items: IOrderItem[];
  company: IStore;
  customer: IUser | string;
  serviceMethod: {
    method: ServiceMethodEnums;
    address?: string;
    table?: string;
  };
}

export interface IGetOrderHistoryResponse extends CommonResponse {
  data: {
    total: number;
    orders: IOrder[];
  };
}

export interface IGetOrderDetailResponse extends CommonResponse {
  data: {
    order: IOrder;
    statusCode: number;
  };
}

export interface ICreateOrderResponse extends CommonResponse {
  data: {
    message: string;
    statusCode: number;
    order: IOrder;
    url: string;
  };
}
