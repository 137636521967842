import React, { useState } from "react";
import { TextAreaProps as TextAreaPropsAntd } from "antd/lib/input";
import TextAreaAntd from "antd/lib/input/TextArea";
import classNames from "classnames";
import ReactLoading from "react-loading";

import Icon from "../Icon";

import "./TextArea.less";

interface TextAreaProps extends TextAreaPropsAntd {
  prefix?: string;
  suffixIcon?: string;
  suffixSize?: number;
  suffixColor?: string;
  onClickSuffixIcon?: () => void;
  loading?: boolean;
  variant?: "primary" | "secondary" | "simple";
}

const TextArea: React.FC<TextAreaProps> = (props) => {
  const {
    prefix,
    suffixIcon,
    onClickSuffixIcon,
    loading,
    variant = "primary",
    suffixSize = 24,
    suffixColor = "#E0E0E0",
    ...rest
  } = props;
  const [focused, setFocused] = useState(false);

  return (
    <div className={classNames("textarea", variant, { focused })}>
      {prefix && (
        <span className="text-[#7F859F] text-xs font-medium whitespace-nowrap pl-4">
          {prefix}
        </span>
      )}

      <TextAreaAntd
        rows={3}
        style={{
          backgroundColor: "transparent",
          border: "none",
          outline: "none",
          color: "white",
          padding: 16,
          paddingRight: 40,
        }}
        autoSize={{ minRows: 1, maxRows: 2 }}
        bordered={false}
        {...rest}
        onFocus={(e) => {
          setFocused(true);
        }}
        onBlur={(e) => {
          setFocused(false);
        }}
      />

      {suffixIcon && (
        <div
          className="absolute -translate-y-1/2 cursor-pointer icon right-4 top-1/2"
          onClick={onClickSuffixIcon}
        >
          {loading ? (
            <ReactLoading
              width={24}
              height={24}
              type="bubbles"
              color="#E0E0E0"
            />
          ) : (
            <Icon
              icon={suffixIcon}
              width={suffixSize}
              height={suffixSize}
              color={suffixColor}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default TextArea;
