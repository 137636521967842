import { atom } from "recoil";

import { ICart } from "./cart.type";

const cartList = atom<ICart[]>({
  key: "cart_list",
  default: [],
});

const CartAtom = { cartList };

export default CartAtom;
