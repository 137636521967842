import { atom } from "recoil";

import { Message } from "./message.type";

interface MessageList {
  page: number;
  pageSize: number;
  data: Message[];
  total: number;
  search?: string;
  offsetAdd: number;
  offsetAddNotLoad: number;
}

const list = atom<MessageList>({
  key: "messageList",
  default: {
    page: 1,
    pageSize: 20,
    data: [],
    total: 0,
    offsetAdd: 0,
    offsetAddNotLoad: 0,
  },
});

const MessageAtom = { list };

export default MessageAtom;
