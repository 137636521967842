import { setRecoil } from "recoil-nexus";

import Api from "../api";
import CartAtom from "./cart.atom";
import {
  CartPayload,
  ICreateCartResponse,
  IGetCartsResponse,
  IUpdateCartResponse,
} from "./cart.type";
import { StorageConstant } from "../storage";
import getCustomerId from "~/utils/getCustomerId";

const getList = async (userId?: string) => {
  try {
    const customerId = userId || getCustomerId();

    if (!customerId) {
      setRecoil(CartAtom.cartList, []);
      return;
    }

    const res = await Api<IGetCartsResponse>({
      url: `/v1/carts/customers/${customerId}`,
      method: "GET",
    });

    if (res.data.statusCode === 200) {
      setRecoil(CartAtom.cartList, res.data.carts);
      return res.data.carts;
    }

    return undefined;
  } catch (error) {
    return undefined;
  }
};

const update = async (
  cartId: string,
  items: { id: string; quantity: number; note: string }[]
) => {
  try {
    const res = await Api<IUpdateCartResponse>({
      url: `/v1/carts/${cartId}`,
      method: "PATCH",
      data: { items },
    });

    if (res.data.statusCode === 200) {
      return res;
    }

    return undefined;
  } catch (error) {
    return undefined;
  }
};

const create = async (data: CartPayload) => {
  try {
    const customer_id = localStorage.getItem(StorageConstant.CUSTOMER_ID);

    const res = await Api<ICreateCartResponse>({
      url: "/v1/carts",
      method: "POST",
      params: { customer_id },
      data,
    });

    if (res.data.statusCode === 200) {
      localStorage.setItem(
        StorageConstant.CUSTOMER_ID,
        res?.data?.cart?.customer
      );
      return res.data;
    }

    return undefined;
  } catch (error) {
    return undefined;
  }
};

const CartAPI = { getList, update, create };

export default CartAPI;
