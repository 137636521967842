import en from "./languages/en";

const { translation } = en;

const transKeys: typeof translation | Record<string, string> = {};

Object.keys(translation).forEach((key: string) => (transKeys[key] = key));

const Trans: typeof translation | Record<string, string> = transKeys;

export default Trans;
