import { atom } from "recoil";

import {
  ApprovalStatus,
  ICategory,
  IProduct,
  ProductStatus,
} from "./products.type";

interface IProductDetails {
  product?: IProduct;
  related: IProduct[];
}

const productDetails = atom<IProductDetails>({
  key: "product_details",
  default: {
    related: [],
  },
});

interface IProductList {
  page: number;
  pageSize: number;
  data: IProduct[];
  total: number;
  name?: string;
  sortBy?: string;
  sortOrder?: string;
  status?: ProductStatus;
  loading?: boolean;
  approval_status?: ApprovalStatus;
}

const productList = atom<IProductList>({
  key: "product_list",
  default: {
    page: 1,
    pageSize: 20,
    data: [],
    total: 0,
  },
});

const productListFilter = atom<IProductList>({
  key: "product_list_filter",
  default: {
    page: 1,
    pageSize: 20,
    data: [],
    total: 0,
  },
});

const products = atom<IProductList>({
  key: "products",
  default: {
    page: 1,
    pageSize: 10,
    data: [],
    total: 0,
    sortBy: "createdAt",
    sortOrder: "desc",
    loading: false,
  },
});

const categories = atom<ICategory[]>({
  key: "categories",
  default: [],
});

const ProductAtom = {
  productDetails,
  productList,
  productListFilter,
  products,
  categories,
};

export default ProductAtom;
