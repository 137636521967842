import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";

import { UserApi, UserAtom } from "~/services/user";
import AppScreens from "../router.type";

const ProtectedRoute = ({ children }: any) => {
  const nav = useNavigate();
  const user = useRecoilValue(UserAtom.userInfo);

  useEffect(() => {
    !user &&
      UserApi.getDetails().then((res) => !res && nav(AppScreens.SIGN_IN));

    // eslint-disable-next-line
  }, [nav]);

  return <>{children}</>;
};

export default ProtectedRoute;
