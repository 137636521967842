import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";

import { AppScreens } from "~/router";
import { paymentStatusModal } from "~/services/app";
import { OrderApi, OrderStatusEnum } from "~/services/order";
import { StorageAtom } from "~/services/storage";
import Trans from "~/translation/translationKeys";
import { isMobile } from "~/utils/checkIsMobile";

const ModalPayment: React.FC = () => {
  const [url, setUrl] = useRecoilState(StorageAtom.url);
  const [paymentStatus, setPaymentStatusModal] =
    useRecoilState(paymentStatusModal);
  const { t } = useTranslation();
  const nav = useNavigate();

  useEffect(() => {
    document.addEventListener(
      "visibilitychange",
      () => {
        if (document.hidden && paymentStatus.orderId && url && isMobile()) {
          OrderApi.getOrderDetail(paymentStatus.orderId).then((res) => {
            if (!res) {
              return;
            }

            if (res.status === OrderStatusEnum.CANCELLED) {
              setUrl("");

              setPaymentStatusModal({
                ...paymentStatus,
                payment: false,
                message: t(Trans.payment_failed),
                orderId: res._id,
                open: true,
                status: OrderStatusEnum.CANCELLED,
              });

              nav(`${AppScreens.STORES}/${paymentStatus.companyId}`);
            }

            if (res.status === OrderStatusEnum.COMPLETED) {
              setUrl("");

              setPaymentStatusModal({
                ...paymentStatus,
                payment: true,
                message: t(Trans.payment_successful),
                orderId: res._id,
                open: true,
                status: OrderStatusEnum.COMPLETED,
              });
            }
          });
        }
      },
      false
    );

    return () => {
      document.removeEventListener("visibilitychange", () => {});
    };
  }, [nav, paymentStatus, setPaymentStatusModal, setUrl, t, url]);

  if (!url) {
    return null;
  }

  const handCancelOrder = () => {
    OrderApi.updateStatus(
      paymentStatus.orderId,
      OrderStatusEnum.CANCELLED
    ).then(() => {
      setUrl("");
      setPaymentStatusModal({
        ...paymentStatus,
        payment: false,
        message: t(Trans.payment_failed),
        open: true,
        status: OrderStatusEnum.CANCELLED,
      });
      nav(`${AppScreens.STORES}/${paymentStatus.companyId}`);
    });
  };

  return (
    <div className="fixed flex flex-col inset-0 bg-[rgba(0,0,0,0.5)] z-[99]">
      <div className="flex items-center justify-between p-4 bg-black">
        <h2 className="mb-0 text-lg font-medium text-white">
          {t(Trans.make_payment)}
        </h2>
        <p
          className="mb-0 text-[#ff4343] cursor-pointer font-medium text-sm"
          onClick={handCancelOrder}
        >
          {t(Trans.cancel)}
        </p>
      </div>
      <iframe title="OnePay Iframe" src={url} width="100%" className="flex-1" />
    </div>
  );
};

export default ModalPayment;
