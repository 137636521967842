import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";

import Trans from "~/translation/translationKeys";
import { MessageApi } from "~/services/message";
import { CommonAtom } from "~/services/common";
import TextArea from "../TextArea";

import "./ChatModal.less";

interface ChatInputProps {
  loadingMessage: boolean;
  scrollToBottom: () => void;
}

const ChatInput: React.FC<ChatInputProps> = ({
  loadingMessage,
  scrollToBottom,
}) => {
  const [content, setContent] = useState("");
  const [loadingSendMessage, setLoadingSendMessage] = useState(false);
  const { t } = useTranslation();
  const openChatModal = useRecoilValue(CommonAtom.openChatModal);

  const onSend = () => {
    if (content?.trim() && !loadingMessage && !loadingSendMessage) {
      setLoadingSendMessage(true);

      const data = {
        content: content?.trim(),
        conversation: openChatModal.conversationId,
      };

      MessageApi.createMessage(data)
        .then((newMessage) => {
          if (newMessage) {
            setContent("");
            scrollToBottom();
            setTimeout(() => {
              document.getElementById("chat-input")?.focus();
            }, 100);
          }
        })
        .finally(() => setLoadingSendMessage(false));
    }
  };

  return (
    <div className="chat-modal-footer">
      <TextArea
        id="chat-input"
        autoFocus
        placeholder={t(Trans.how_can_we_help_you)}
        onKeyDown={(e) => {
          if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            onSend();
          }
        }}
        suffixIcon="send"
        variant="simple"
        suffixSize={30}
        suffixColor="#000000"
        value={content}
        // style={{ background: "white" }}
        disabled={loadingSendMessage || loadingMessage}
        loading={loadingSendMessage || loadingMessage}
        onChange={(e) => setContent(e.target.value)}
        onClickSuffixIcon={onSend}
      />
    </div>
  );
};

export default ChatInput;
