import { setRecoil } from "recoil-nexus";

import Api from "../api";
import { IGetLocalesResponse } from "./common.type";
import CommonAtom from "./common.atom";

const getLocales = async () => {
  try {
    const res = await Api<IGetLocalesResponse>({
      url: "/v1/common/locales",
      method: "GET",
    });

    if (res.data) {
      setRecoil(CommonAtom.locales, res.data);

      return res.data;
    }

    return undefined;
  } catch (error) {
    return undefined;
  }
};

const CommonApi = { getLocales };

export default CommonApi;
