import { IStore } from "../store";

export enum ProductStatus {
  ACTIVE = 1,
  INACTIVE = 2,
  DELETED = 3,
}

export enum ApprovalStatus {
  APPROVED = "APPROVED",
  PENDING = "PENDING",
  EXPIRED = "EXPIRED",
  REJECTED = "REJECTED",
}

export interface IProduct {
  _id: string;
  images: string[];
  categories: string[];
  name: string;
  description: string;
  short_description: string;
  shortDescription: string;
  price: number;
  discount: number;
  discount_type: ProductDiscountType;
  discountType: ProductDiscountType;
  quantity: number;
  company: IStore;
  qrCode: string;
  createdAt: string;
  updatedAt: string;
  status: ProductStatus;
  enableCalendar: boolean;
  approval_status: {
    status: ApprovalStatus;
    activation_date?: string;
    expiration_date?: string;
  };
}

export enum ProductDiscountType {
  AMOUNTS = 1,
  PERCENTAGES = 2,
}

export interface ICategory {
  _id: string;
  name: string;
  description: string;
  createdAt: string;
  updatedAt: string;
  emoji: string;
}

export interface IProductsByCategory {
  category: ICategory;
  products: IProduct[];
}

export interface IGetProductListResponse extends CommonResponse {
  data: {
    products: IProduct[];
    statusCode: number;
    total: number;
  };
}

export interface IGetProductDetailsResponse extends CommonResponse {
  data: {
    product: IProduct;
    related: IProduct[];
    statusCode: number;
  };
}

export interface IGetProductsResponse extends CommonResponse {
  data: {
    products: IProduct[];
    statusCode: number;
    total: number;
  };
}

export interface IGetCategoriesResponse extends CommonResponse {
  data: {
    categories: ICategory[];
    statusCode: number;
  };
}

export interface IUploadImageResponse extends CommonResponse {
  data: {
    files: { url: string }[];
  };
  statusCode: number;
}

export interface ICreateUpdateProductResponse extends CommonResponse {
  data: {
    statusCode: number;
  };
}
