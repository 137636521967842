import React from "react";
import { useNavigate } from "react-router-dom";

import { AppScreens } from "~/router";
import Logo from "~/assets/svgs/logo.svg";
import { isMobile } from "~/utils/checkIsMobile";

import "./HeaderPrivacy.less";

const HeaderPrivacy: React.FC = () => {
  const nav = useNavigate();

  return (
    <div className="header-privacy">
      <img
        src={Logo}
        alt="logo"
        width={45}
        height={58}
        className="cursor-pointer"
        onClick={() => !isMobile() && nav(AppScreens.HOME)}
      />
    </div>
  );
};

export default HeaderPrivacy;
