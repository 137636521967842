import classNames from "classnames";
import React, { useState } from "react";
import ReactLoading from "react-loading";

import Icon from "../Icon";
import { InputProps } from "./Input.type";

import "./Input.less";

const Input: React.FC<InputProps> = ({
  label,
  errorMessage,
  type,
  iconName,
  iconSize = 24,
  iconColor = "#666666",
  className,
  variant = "primary",
  width,
  maxWidth,
  height,
  onBlur,
  onFocus,
  disabled,
  clearText,
  textBox,
  suffixIcon,
  onClickSuffixIcon,
  loading,
  containerClassName,
  minWidth,
  ...rest
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [focused, setFocused] = useState(false);

  return (
    <div
      className={classNames(
        "input-container group",
        variant,
        containerClassName,
        {
          disabled,
        }
      )}
      style={{ minWidth }}
    >
      {label && <label className="label">{label}</label>}
      <div
        className={classNames("input", variant, className, {
          focused: focused,
        })}
        style={{ width, height, maxWidth }}
      >
        {textBox && (
          <span className="text-[#7F859F] text-xs font-medium whitespace-nowrap">
            {textBox}
          </span>
        )}
        {iconName && (
          <div className="icon">
            <Icon icon={iconName} color={iconColor} />
          </div>
        )}
        {type === "password" && (
          <div className="icon">
            {showPassword ? (
              <Icon
                icon="eye-show"
                color="#666666"
                onClick={() => setShowPassword(!showPassword)}
              />
            ) : (
              <Icon
                icon="eye-hide"
                color="#666666"
                onClick={() => setShowPassword(!showPassword)}
              />
            )}
          </div>
        )}
        <input
          type={showPassword ? "text" : type}
          {...rest}
          onFocus={(e) => {
            onFocus?.(e);
            setFocused(true);
          }}
          onBlur={(e) => {
            onBlur?.(e);
            setFocused(false);
          }}
          disabled={disabled}
        />
        {suffixIcon && (
          <div
            className={classNames("icon", {
              "cursor-pointer": !!onClickSuffixIcon,
            })}
            onClick={onClickSuffixIcon}
          >
            {loading ? (
              <ReactLoading
                width={24}
                height={24}
                type="bubbles"
                color="#7D828B"
              />
            ) : (
              <Icon icon={suffixIcon} color="#666666" />
            )}
          </div>
        )}

        {rest.value && clearText && (
          <Icon
            icon="cancel"
            color="#ccc"
            className="invisible cursor-pointer group-hover:visible"
            size={16}
            onClick={clearText}
          />
        )}
      </div>
      {errorMessage && <span className="error-message">{errorMessage}</span>}
    </div>
  );
};

export default Input;
