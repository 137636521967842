import axios, { AxiosResponse, Method, ResponseType } from "axios";
import { toast } from "react-toastify";
import { getRecoil } from "recoil-nexus";

import { StorageAtom, StorageConstant } from "../storage";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 15000,
  validateStatus: (status: number) => status < 400,
});

instance.interceptors.request.use((config: any) => {
  const token =
    getRecoil(StorageAtom.token) ||
    localStorage.getItem(StorageConstant.ACCESS_TOKEN);

  if (token) {
    return {
      ...config,
      headers: {
        ...config.headers,
        authorization: `Bearer ${token}`,
      },
    };
  } else {
    return {
      ...config,
    };
  }
});

const Api = async <T>({
  url,
  method = "POST",
  data,
  params,
  responseType,
  headers,
  baseURL,
}: {
  url: string;
  method?: Method;
  data?: any;
  params?: any;
  responseType?: ResponseType;
  headers?: any;
  baseURL?: string;
}): Promise<T> => {
  return instance
    .request({
      method,
      url,
      data,
      params,
      responseType,
      headers,
      baseURL,
    })
    .then((resp: AxiosResponse<any>) => {
      return resp.data;
    })
    .catch((error) => {
      const errResponse = error?.response?.data?.data;

      if (errResponse?.statusCode === 403) {
        localStorage.removeItem(StorageConstant.ACCESS_TOKEN);
        // window.location.replace(AppScreens.SIGN_IN);
        return;
      }

      if (errResponse) {
        toast.error(errResponse.message);
        throw errResponse;
      }

      throw error;
    });
};

export default Api;
