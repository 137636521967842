import { Modal } from "antd";
import React, { useState } from "react";
import { useRecoilValue, useResetRecoilState } from "recoil";
import { useTranslation } from "react-i18next";

import { CommonAtom } from "~/services/common";
import Button from "../Button";
import Trans from "~/translation/translationKeys";

const ModalConfirm: React.FC = () => {
  const showModalConfirm = useRecoilValue(CommonAtom.showModalConfirm);
  const resetShowModalConfirm = useResetRecoilState(
    CommonAtom.showModalConfirm
  );
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const handleConfirm = async () => {
    setLoading(true);

    try {
      await showModalConfirm?.onConfirm?.();
    } finally {
      resetShowModalConfirm();
      setLoading(false);
    }
  };

  return (
    <Modal
      destroyOnClose
      open={showModalConfirm.open}
      footer={false}
      title={false}
      closeIcon={false}
      closable={false}
      centered
      width={520}
    >
      <div className="flex flex-col items-center justify-center p-5 gap-y-3">
        <h2 className="text-xl text-[#da9f69] font-bold">
          {showModalConfirm.title}
        </h2>

        <p className="text-sm text-center text-white">
          {showModalConfirm.description}
        </p>

        <div className="flex justify-between w-full mt-6 gap-x-3">
          <Button variant="primary_outline" onClick={resetShowModalConfirm}>
            {t(Trans.cancel)}
          </Button>

          <Button onClick={handleConfirm} disabled={loading} loading={loading}>
            Ok
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalConfirm;
