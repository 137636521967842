const AppScreens = {
  HOME: "/",
  STORES: "/stores",
  PRODUCTS: "/products",
  SEARCH: "/search",
  SIGN_IN: "/sign-in",
  SIGN_UP: "/sign-up",
  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/reset-password",
  PRODUCT: "/products",
  PROFILE: "/profile",
  PROFILE_PRODUCT_MANAGEMENT: "/profile/product-management",
  PROFILE_ORDER_HISTORY: "/profile/order-history",
  PROFILE_CHAT: "/profile/chat",
  PROFILE_RESERVATION_HISTORY: "/profile/reservation-history",
  PROFILE_CHANGE_PASSWORD: "/profile/change-password",
  RESERVATION: "/reservation",
  PRIVACY_POLICY: "/privacy-policy",
  PRIVACY_POLICY_VI: "/privacy-policy/vi",
  TERMS_OF_USE: "/terms-of-use",
  TERMS_OF_USE_VI: "/terms-of-use/vi",
  NOT_FOUND: "*",
  CART: "/carts",
  ORDER: "/orders",
  ABOUT_US: "/about-us",
  MENUS: "/menus",
};

export default AppScreens;
