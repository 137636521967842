import React from "react";
import classNames from "classnames";
import ReactLoading from "react-loading";

import { ButtonProps } from "./Button.type";
import Icon from "../Icon";

import "./Button.less";

const Button: React.FC<ButtonProps> = ({
  variant = "primary",
  children,
  className,
  width,
  maxWidth,
  height,
  iconName,
  iconSize,
  iconColor,
  loading,
  size = "middle",
  style,
  iconStyle,
  ...rest
}) => {
  return (
    <button
      className={classNames("button", className, variant, size)}
      {...rest}
      style={{ width, height, maxWidth, ...style }}
    >
      {loading ? (
        <ReactLoading
          type="spin"
          color="white"
          width={16}
          height={16}
          className="mx-auto"
        />
      ) : (
        <div className="button-children">
          <span className="text">{children}</span>
          {iconName && (
            <Icon
              className="icon"
              icon={iconName}
              size={iconSize}
              color={iconColor}
              style={iconStyle}
              width={iconSize}
              height={iconSize}
            />
          )}
        </div>
      )}
    </button>
  );
};

export default Button;
