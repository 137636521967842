const vi = {
  translation: {
    products: "Sản phẩm",
    stores: "Cửa hàng",
    stores_desc:
      "Khám phá các địa điểm chất lượng hàng đầu của chúng tôi và nhiều hơn nữa…",
    there_are_no_stores_yet:
      "Hiện tại chưa có cửa hàng nào. Bạn hãy quay lại sau nhé.",
    there_are_no_products_yet:
      "Hiện tại chưa có sản phẩm nào. Bạn hãy quay lại sau nhé.",
    search_stores: "Khóa học, Nhà hàng, Bar, Cafe, Tiệm bánh,...",
    no_stores: "Không có cửa hàng nào",
    no_order: "Không có đơn hàng nào",
    no_reservation: "Không có lịch đặt nào",
    search: "Tìm kiếm",
    sign_in: "Đăng nhập",
    sign_up: "Đăng ký",
    log_out: "Đăng xuất",
    cart: "Giỏ hàng",
    account: "Tài khoản",
    edit_information: "Chỉnh sửa thông tin",
    order_history: "Lịch sử đơn hàng",
    reservation_history: "Lịch sử đặt bàn",
    change_password: "Đổi mật khẩu",
    there_number_results_for: "Có {{total}} kết quả tìm kiếm cho",
    password: "Mật khẩu",
    old_password: "Mật khẩu cũ",
    new_password: "Mật khẩu mới",
    confirm_name: "Nhập lại {{text}}",
    save: "Lưu",
    save_and_request: "Lưu và gửi yêu cầu",
    show_detail: "Xem chi tiết",
    hide_detail: "Ẩn chi tiết",
    send_reservation: "Gửi lịch đặt",
    order_status: "Trạng thái đơn hàng",
    status: "Trạng thái",
    order_desc: "Đơn đặt hàng của bạn sẽ sẵn sàng vào lúc",
    store_info: "Thông tin cửa hàng",
    product_info: "Thông tin sản phẩm",
    information: "Thông tin",
    delete_account: "Xoá tài khoản",
    cancel: "Huỷ bỏ",
    your_account_will_be_deleted_permanently:
      "Tài khoản của bạn sẽ bị xóa vĩnh viễn",
    discover_store: "Khám phá cửa hàng",
    home: "Trang chủ",
    book_a_table: "Đặt bàn",
    can_not_find_store: "Không tìm thấy cửa hàng",
    no_results_try_another_keyword:
      "Không có kết quả nào. Hãy thử từ khoá khác.",
    reservation_information: "Thông tin đặt bàn",
    detail_information: "Thông tin chi tiết",
    reservation_date: "Ngày đặt bàn",
    reservation_time: "Thời gian đặt bàn",
    time: "Thời gian",
    total_guest: "Tổng số khách",
    special_request_not_required: "Yêu cầu đặc biệt ( không bắt buộc )",
    placeholder_special_request_not_required:
      "Ví dụ: Dịch vụ sinh nhật, Vị trí bàn, …",
    make_a_reservation: "Đặt bàn",
    restaurant_information: "Thông tin cửa hàng",
    as_seller: "với tư cách Người bán",
    do_not_have_an_account: "Bạn không có tài khoản?",
    remember_password: "Nhớ mật khẩu",
    forget_password: "Quên mật khẩu",
    forgot_password: "Quên mật khẩu",
    name_at_least_6_characters: "{{text}} tối thiểu là 6 ký tự",
    confirm_password_error: "Nhập lại mật khẩu phải giống {{text}}",
    agree_width:
      "Đăng ký tài khoản với myOKIES có nghĩa là bạn đồng ý với chúng tôi",
    agree_width_myoutie:
      "Đăng ký tài khoản với my-outie có nghĩa là bạn đồng ý với chúng tôi",
    terms_of_service: "Điều khoản dịch vụ",
    privacy_policy: "Chính sách bảo mật",
    already_have_an_account: "Bạn đã có tài khoản?",
    set: "Đặt",
    reset_password: "Đặt lại mật khẩu",
    enter_your_otp: "Nhập mã OTP của bạn",
    sent_otp: "Chúng tôi đã gửi (OTP)",
    to_your: "đến {{text}}",
    resend_now: "Gửi lại ngay bây giờ",
    did_not_receive_otp: "Không nhận được OTP?",
    name_unsuccessful: "{{text}} thất bại",
    not_permissions: "Bạn không có quyền",
    please_login_again_in_a_few_minutes: "Vui lòng đăng nhập lại sau ít phút",
    please_verify_your_account: "Hãy xác thực tài khoản của bạn",
    booking: "Đặt bàn",
    back_homepage: "Trở về trang chủ",
    a_reservation_confirmation_is_sent_to_your_email:
      "Thông tin đặt bàn đã được gửi tới email của bạn.",
    page_not_found: "Không tìm thấy trang",
    no_data: "Không có dữ liệu",
    please_choose_time_greater_than_current_time:
      "Vui lòng chọn thời gian lớn hơn thời gian hiện tại",
    about_us: "Về chúng tôi",
    about_us_title: "Tương lai của Hệ thống POS và Thu ngân",
    about_us_content: "Chuyển đổi hoạt động kinh doanh của bạn với ",
    about_us_content_2:
      "- giải pháp tự động hóa tất cả trong một giúp hợp lý hóa quy trình đặt hàng và thanh toán. Công nghệ tiên tiến của chúng tôi, sử dụng mã QR kỹ thuật số cho menu, dịch vụ và sản phẩm, mang lại trải nghiệm dịch vụ khách hàng đặc biệt, nâng cao hiệu quả và giảm chi phí vận hành. Đưa doanh nghiệp của bạn lên một tầm cao mới với ",
    register_business_account: "Đăng ký tài khoản doanh nghiệp",
    about_us_address:
      "Vincom Center Đồng Khởi, Tầng 17-11, 72 Lê Thánh Tôn, Phường Bến Nghé, Quận 1, TP.HCM, Việt Nam",
    scan_qr_code_to_order_and_pay: "Quét mã QR để đặt hàng và thanh toán",
    online_and_offline_sales_in_one_app:
      "Bán hàng trực tuyến và ngoại tuyến trong một ứng dụng",
    unique_luxury_design: "Thiết Kế Sang Trọng Độc Đáo",
    modern_automation_technology: "Công nghệ tự động hóa hiện đại",
    multiple_payment_options: "Nhiều phương thức thanh toán",
    online_operation_management: "Quản lý vận hành trực tuyến",
    fast_order_and_secure_payment: "Đặt hàng nhanh và thanh toán an toàn",
    customer_loyalty_program: "Chương trình khách hàng thân thiết",
    digital_qr_menu_or_product_list:
      "Menu QR kỹ thuật số hoặc Danh sách sản phẩm",
    more_customers_more_profit: "Nhiều khách hàng hơn. Thêm lợi nhuận",
    dedicate_for_hight_end_customer_shopping_and_dining_experience:
      "Dành cho trải nghiệm mua sắm và ăn uống của khách hàng cao cấp",
    reduce_operation_cost: "Giảm chi phí vận hành",
    access_24_7_with_auto_sales_reports:
      "Truy cập 24/7 với Báo cáo bán hàng tự động",
    improve_customer_service_experience:
      "Cải thiện trải nghiệm dịch vụ khách hàng",
    e_wallets_credit_debit_cards_and_cash_option:
      "Ví điện tử, Thẻ tín dụng/Thẻ ghi nợ và Tùy chọn tiền mặt.",
    reward_your_customers_width_point_redeemption_discount_price_and_many_more:
      "Thưởng cho khách hàng của bạn bằng cách đổi điểm, giảm giá và nhiều hơn nữa.",
    main_features: "Những tính năng chính",
    business_application: "Ứng dụng kinh doanh",
    food_and_beverage_businesses: "Doanh nghiệp thực phẩm và đồ uống",
    food_and_beverage_businesses_content:
      "Từ nhà hàng đến quán cà phê, xử lý đơn hàng nhanh chóng với myOKIES - QR Menu. Chỉ cần quét QR Code để đặt hàng và thanh toán trực tiếp trên điện thoại. Quản lý doanh nghiệp của bạn theo thời gian thực, báo cáo bán hàng tự động miễn phí hàng ngày, hàng tháng và hàng năm. Giảm thiểu chi phí vận hành và cải thiện trải nghiệm dịch vụ khách hàng của bạn.",
    retail_business: "Kinh doanh bán lẻ",
    retail_business_content:
      "Đặt doanh nghiệp của bạn khác biệt với những doanh nghiệp khác bằng dịch vụ công nghệ của chúng tôi để bán hàng trực tuyến và tại cửa hàng bằng cách mua qua Mã QR với thông tin sản xuất. Dễ dàng vận hành và quản lý cửa hàng của bạn hoàn toàn trực tuyến, truy cập 24/7 và chấp nhận tất cả các loại tùy chọn thanh toán từ ví điện tử đến tất cả thẻ ghi nợ/thẻ tín dụng với myOKIES. Nâng cao trải nghiệm mua sắm của khách hàng, chỉ cần quét mã QR để đặt hàng, thanh toán trực tiếp trên ứng dụng và nhận hàng ngay.",
    service_businesses: "Doanh nghiệp dịch vụ",
    service_businesses_content:
      "Nhanh chóng và dễ dàng để đặt hàng và thanh toán cho dịch vụ của bạn với myOKIES bằng cách quét Mã QR cho Danh sách Dịch vụ. Quản lý tất cả các khía cạnh kinh doanh của bạn hoàn toàn trực tuyến, truy cập thông tin chi tiết quản lý của bạn từ mọi nơi và mọi lúc. Cải thiện trải nghiệm dịch vụ khách hàng của bạn một cách đơn giản với mã QR có thể quét được để đặt hàng và thanh toán trực tiếp trên điện thoại của họ cũng như lên lịch các cuộc hẹn cho doanh nghiệp của bạn chỉ bằng vài cú nhấp chuột.",
    restaurant: "Nhà hàng",
    coffee_shop: "Quán cà phê",
    bar_lounge: "Quầy bar, Phòng chờ",
    hotel_resort_in_room_dining_service:
      "Dịch Vụ Ăn Tại Phòng Khách Sạn/Resort",
    dessert_ice_cream_bakery_shop: "Món Tráng Miệng, Kem, Tiệm Bánh",
    home_decor_furniture: "Trang Trí Nhà Cửa, Nội Thất",
    wine_spirit_and_cigar: "Rượu, rượu mạnh và xì gà",
    flower_boutique_shop: "Cửa hàng hoa nhỏ",
    airport_duty_free: "Miễn thuế sân bay",
    book_store: "Tiệm sách",
    cosmetic: "Mỹ phẩm",
    art_gallery: "Triển lãm nghệ thuật",
    beauty_salon_hair_spa_nails_massage:
      "Thẩm mỹ viện - Tóc, Spa, Móng, Massage",
    hotel_resort_in_room_spa_service: "Dịch Vụ Spa Tại Phòng Khách Sạn/Resort",
    gym_membership: "Thành viên phòng tập thể dục",
    event_ticket: "Vé sự kiện",
    health_care_clinic_hospital: "Chăm sóc sức khỏe, phòng khám, bệnh viện",
    vet_service: "Dịch vụ thú y",
    casino_gambling_chips: "Sòng bạc cờ bạc",
    our_service_is_currently_available_to:
      "Dịch vụ của chúng tôi hiện có sẵn cho:",
    tax_code: "Mã số thuế",
    user_not_found: "Không tìm thấy người dùng",
    are_you_sure_you_want_to_logout: "Bạn có chắc chắn bạn muốn đăng xuất?",
    are_you_sure_you_want_to_cancel_this_reservation:
      "Bạn có chắc chắn muốn hủy lịch đặt này không?",
    back: "Quay lại",
    welcome_back: "Chào mừng trở lại, {{name}}",
    draft: "Mới tạo",
    confirmed: "Đã xác nhận",
    special_request: "Yêu cầu đặc biệt",
    get_pdf_reservation: "Lấy file PDF của lịch đặt",
    cancel_reservation: "Hủy lịch đặt",
    business_brochure: "Business Brochure",
    eliminate_missing_order: "Loại bỏ đơn hàng bị thiếu",
    orders_are_recorded_by_myokies_system_directly_from_customer_to_business_owner_management_dashboard:
      "Đơn hàng được hệ thống myOKIES ghi nhận trực tiếp từ khách hàng đến bảng điều khiển quản lý chủ doanh nghiệp",
    inflight_dining: "Bữa ăn trên máy bay",
    your_account_has_been_verified: "Tài khoản của bạn đã được xác nhận",
    other: "Khác",
    and: "và",
    reservation_created:
      "Cảm ơn bạn đã đặt chỗ. Yêu cầu của bạn đang chờ xác nhận. Cửa hàng sẽ xem xét đặt chỗ của bạn và gửi email xác nhận cho bạn trong thời gian sớm nhất.",
    banner_chart_title:
      "Bảng so sánh giữa <span class='important'>myOKIES</span> và hệ thống POS",

    please_choose: "Vui lòng chọn {{text}}",
    category: "Danh mục",
    search_products: "Tìm kiếm sản phẩm",
    reservation: "Đặt chỗ",
    open: "Mở cửa",
    closed: "Đóng cửa",
    add_to_cart: "Thêm vào giỏ hàng",
    name_successful: "{{text}} thành công",
    in_stock: "Kho",
    out_of_stock: "Hết hàng",
    see_more: "Xem nhiều hơn",
    see_less: "Thu gọn",
    back_to_products: "Quay lại sản phẩm",
    you_may_also_like: "Có thể bạn sẽ thích",
    your_cart: "Giỏ hàng của bạn",
    your_cart_is_empty: "Giỏ hàng của bạn trống",
    please_choose_company: "Vui lòng chọn cửa hàng muốn thanh toán",
    continue: "Tiếp tục",
    back_to_choose: "Quay lại để chọn",
    continue_to_check_out: "Tiếp tục thanh toán",
    remove: "Gỡ bỏ",
    note: "Ghi chú",
    user_name: "Tên người dùng",
    please_enter_your: "Vui lòng nhập {{text}}",
    email_address: "Địa chỉ email",
    phone_number: "Số điện thoại",
    service_method: "Phương thức dịch vụ",
    payment_method: "Phương thức thanh toán",
    location: "Địa điểm",
    address: "Địa chỉ",
    credit_debit_card: "Thẻ Credit / Debit",
    atm_cart: "Thẻ ATM",
    e_wallet: "Ví điện tử",
    make_payment: "Thanh toán",
    please_make_payment_at_pick_up_station: "Vui lòng thanh toán tại quầy",
    back_to_your_cart: "Trở về giỏ hàng của bạn",
    payment_failed: "Giao dịch không thành công",
    payment_successful: "Giao dịch thành công",
    total_price: "Tổng giá tiền",
    one_pay_error_99: "Người sử dụng hủy giao dịch.",
    check_order_detail: "Xem thông tin đơn hàng",
    continue_shopping: "Tiếp tục mua sắm",
    order: "Đơn hàng",
    order_completed: "Cảm ơn bạn rất nhiều vì đơn đặt hàng của bạn.",
    store_name: "Tên cửa hàng",
    store_address: "Địa chỉ cửa hàng",
    store_email: "Email cửa hàng",
    store_phone_number: "Số điện thoại cửa hàng",
    order_information: "Thông tin đơn hàng",
    order_no: "Mã đơn hàng",
    date: "Ngày",
    store_information: "Thông tin cửa hàng",
    payment_information: "Thông tin thanh toán",
    delivery_option: "Phương thức nhận hàng",
    delivery_address: "Địa chỉ nhận hàng",
    payment_status: "Trạng thái thanh toán",
    customer_information: "Thông tin khách hàng",
    name: "Tên",
    email: "Email",
    back_to_home: "Trở về trang chủ",
    pending: "Đang xử lý",
    paid: "Đã thanh toán",
    cancelled: "Đã huỷ",
    table: "Tại bàn",
    pick_up: "Tới lấy",
    delivery: "Giao hàng",
    cash: "Tiền mặt",
    processing: "Đang xử lý",
    change_language: "Thay đổi ngôn ngữ",
    choose_a_language: "Chọn 1 ngôn ngữ",
    send_receipt: "Gửi hoá đơn",
    the_store_is_closed: "Cửa hàng đã đóng cửa.",
    product_information: "Thông tin sản phẩm",
    there_are_no_products_to_pay_for: "Không có sản phẩm nào để thanh toán",
    maximum_name_is_60_characters: "Tên tối đa là 60 ký tự",
    your_name_is_not_valid: "{{text}} không hợp lệ",
    booking_date: "Ngày đặt",
    register_successful:
      "Chào mừng đến với myOKIES! Vui lòng đợi nhóm myOKIES xử lý đăng ký của bạn",
    register_successful_myoutie:
      "Chào mừng đến với my-outie! Vui lòng đợi nhóm my-outie xử lý đăng ký của bạn",
    all: "Tất cả",
    how_can_we_help_you: "Chúng tôi có thể giúp bạn như thế nào?",
    block: "Chặn",
    unblock: "Bỏ chặn",
    order_code_copied: "Đã sao chép mã đơn hàng {{text}}",
    you_are_discussing_this_product: "Bạn đang thảo luận về sản phẩm này",
    do_you_want_to_block_this_conversation:
      "Bạn có muốn chặn cuộc trò chuyện này?",
    do_you_want_to_unblock_this_conversation:
      "Bạn có muốn bỏ chặn cuộc trò chuyện này?",
    if_you_block_you_will_no_longer_be_able_to_message_this_person:
      "Nếu bạn chặn, bạn sẽ không thể nhắn tin cho người này được nữa",
    if_you_unblock_you_can_message_this_person:
      "Nếu bạn bỏ chặn, bạn có thể nhắn tin cho người này",
    you_have_been_blocked_by_this_user: "Bạn đã bị chặn bởi người dùng này",
    you_have_blocked_this_user: "Bạn đã chặn người dùng này",
    chat: "Chat",
    no_conversation: "Không có cuộc trò chuyện nào",
    chat_with_seller: "Trò chuyện với người bán",
    you_have_a_new_message: "Bạn có 1 tin nhắn mới",
    the_store_has_been_disabled: "Cửa hàng đã bị vô hiệu hóa",
    okies_name: "CÔNG TY CỔ PHẦN CÔNG NGHỆ OKIES",
    okies_address:
      "72 Lê Thánh Tôn, Lầu 17-11, P. Bến Nghé, Q1, Tp HCM, Việt Nam",
    issue_date: "Ngày cấp",
    product_management: "Quản lý sản phẩm",
    no: "STT",
    price: "Giá",
    created_on: "Ngày tạo",
    active: "Hoạt động",
    inactive: "Không hoạt động",
    approval_status: "Trạng thái phê duyệt",
    activation_date: "Ngày kích hoạt",
    expiration_date: "Ngày hết hạn",
    expired: "Hết hạn",
    approved: "Đã duyệt",
    pending_for_approval: "Chờ duyệt",
    are_you_sure_you_want_to_delete_this_product:
      "Bạn có chắc chắn muốn xóa sản phẩm này?",
    if_you_delete_this_product_you_will_never_see_it_again:
      "Nếu bạn xóa sản phẩm này, bạn sẽ không bao giờ thấy nó nữa",
    import: "Nhập liệu",
    sort: "Sắp xếp",
    filter: "Lọc",
    field: "Trường",
    ascending: "Tăng dần",
    descending: "Giảm dần",
    select_from_computer: "Chọn file từ máy tính",
    drag_and_drop_file_here: "Kéo thả file vào đây",
    download: "Tải xuống",
    download_sample_product_list: "mẫu",
    download_sample_product_list_continue: "để xem",
    upload_file_error: "Lỗi file upload",
    add_new: "Thêm mới",
    action: "Hành động",
    product_name: "Tên sản phẩm",
    create_new_product: "Tạo sản phẩm mới",
    edit_product: "Chỉnh sửa sản phẩm",
    quantity: "Số lượng",
    discount: "Giảm giá",
    amount: "Giá tiền",
    percentage: "Phần trăm",
    description: "Mô tả",
    enter_text: "Nhập nội dung...",
    image: "Hình ảnh",
    add_image: "Thêm hình ảnh",
    discount_bigger_price: "Giá trị khuyến mãi không lớn hơn giá trị sản phẩm",
    please_upload_picture_with_size_smaller_than_3mb_per_picture:
      "Vui lòng tải lên ảnh có kích thước nhỏ hơn 3MB mỗi ảnh",
    you_can_only_upload_file_jpg_pnd: "Bạn chỉ có thể tải lên tệp JPG/PNG!",
    you_can_only_upload_file_xlsx: "Bạn chỉ có thể tải lên tệp XLSX!",
    product_created: "Sản phẩm đã được tạo",
    product_updated: "Sản phẩm đã được cập nhật",
    product_deteled: "Sản phẩm đã bị xoá",
    as_business: "với tư cách Doanh nghiệp",
    back_to_stores: "Quay lại cửa hàng",
    full_name: "Tên đầy đủ",
    choose_a_category: "Chọn 1 danh mục",
    you_are_discussing_this_order: "Bạn đang thảo luận về đơn hàng này",
    there_are_currently_no_products: "Hiện tại chưa có sản phẩm nào",
    cannot_create_product: "Không thể tạo sản phẩm",
    cannot_update_product: "Không thể chỉnh sửa sản phẩm",
    minimum_image_is_1_image: "Hình ảnh tối thiểu là 1 hình",
    maximum_images_are_20_images: "Hình ảnh tối đa là 20 hình",
    rejected: "Từ chối",
    quantity_invalid: "Số lượng không hợp lệ",
  },
};

export default vi;
