import React, { Suspense, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Layout from "~/layouts/Layout";
import Loading from "~/components/Loading";
import { StorageConstant } from "~/services/storage";
import { UserApi } from "~/services/user";
import { LayoutEnum } from "~/types/route";
import AppRoutes from "./config";
import ProtectedRoute from "./config/ProtectedRoute";

const Router: React.FC = () => {
  const { i18n } = useTranslation();

  const RenderComponent = ({
    layout,
    Component,
    authRequire,
  }: {
    layout?: LayoutEnum;
    Component: any;
    authRequire: boolean;
  }) => {
    const Provider = authRequire ? ProtectedRoute : React.Fragment;

    return (
      <Provider>
        <Layout layout={layout}>
          <Suspense fallback={<Loading />}>
            <Component />
          </Suspense>
        </Layout>
      </Provider>
    );
  };

  useEffect(() => {
    const token = localStorage.getItem(StorageConstant.ACCESS_TOKEN);
    const language = localStorage.getItem(StorageConstant.LANGUAGE);
    if (token) {
      UserApi.getDetails();
    }

    if (language && i18n.language !== language) {
      i18n.changeLanguage(language);
    }
  }, [i18n]);

  return (
    <Routes>
      {AppRoutes.map((route, index) => {
        return (
          <Route
            key={`${route.path}-${index}`}
            path={route.slug}
            element={
              route.component
                ? RenderComponent({
                    layout: route.layout,
                    Component: route.component,
                    authRequire: route.authRequire,
                  })
                : undefined
            }
          >
            {Array.isArray(route.children) &&
              route.children.map((childRoute, childIndex) => (
                <Route
                  index={childRoute.isIndex}
                  key={`${childRoute.path}-${childIndex}`}
                  path={childRoute.slug}
                  element={RenderComponent({
                    layout: childRoute.layout,
                    Component: childRoute.component,
                    authRequire: childRoute.authRequire,
                  })}
                />
              ))}
          </Route>
        );
      })}
    </Routes>
  );
};

export default Router;
