import { CountryCodeEnum } from "~/components/InputPhone/InputPhone.type";
import { IStore } from "../store";

export enum UserRolesEnum {
  ADMIN = 1,
  SERVICE_OWNER = 2,
  CUSTOMER = 3,
}

export enum UserStatusEnum {
  ACTIVE = 1,
  DEACTIVATE = 2,
  UNVERIFIED = 3,
}

export interface IUser {
  _id: string;
  email: string;
  phoneNumber: string;
  token: string;
  status: UserStatusEnum;
  role: UserRolesEnum;
  countryCode: CountryCodeEnum;
  name: string;
  createdAt: string;
  updatedAt: string;
  address: string;
  company?: IStore;
}
export interface IGetDetailsUserResponse extends CommonResponse {
  data: {
    user_profile: IUser;
  };
}
