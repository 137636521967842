export interface IRoute {
  key: string;
  path?: string;
  component?: any;
  authRequire: boolean;
  layout?: LayoutEnum;
  children?: Array<IRoute>;
  isIndex?: boolean;
  slug?: string;
}

export enum LayoutEnum {
  DEFAULT = "default",
  AUTH = "auth",
  PRIVACY = "privacy",
  FORGOT_PASSWORD = "forgot_password",
}
