// @ts-ignore
import io from "socket.io-client";
import { useEffect } from "react";

export const socket = io(process.env.REACT_APP_SOCKET_URL, {
  path: "/api/ws",
  transports: ["websocket"],
});

const useSocket = () => {
  useEffect(() => {
    socket.on("connect", () => {
      console.log("Socket has been connected");
    });

    socket.on("disconnect", () => {
      console.log("Socket has been disconnected");
    });

    socket.on("connect_error", (error: any) => {
      console.log("Socket connect error", JSON.stringify(error, null, 4));
    });

    socket.connect();

    return () => {
      socket.disconnect();
    };

    // eslint-disable-next-line
  }, []);
};

export default useSocket;
