const en = {
  translation: {
    products: "Products",
    stores: "Stores",
    there_are_no_stores_yet: "There are no stores yet. Try to come back later.",
    there_are_no_products_yet:
      "There are no products yet. Try to come back later.",
    search_stores:
      "Workshops, Restaurants, Bars, Coffee shops, Bakery, more...",
    no_stores: "No stores",
    no_order: "No order",
    no_reservation: "No reservation",
    search: "Search",
    sign_in: "Sign in",
    sign_up: "Sign up",
    log_out: "Log out",
    cart: "Cart",
    account: "Account",
    edit_information: "Edit Information",
    order_history: "Order History",
    reservation_history: "Reservation History",
    change_password: "Change password",
    there_number_results_for: "There are {{total}} results for",
    password: "Password",
    old_password: "Old password",
    new_password: "New password",
    confirm_name: "Confirm {{text}}",
    save: "Save",
    save_and_request: "Save and request",
    show_detail: "Show detail",
    hide_detail: "Hide detail",
    send_reservation: "Send Reservation",
    order_status: "Order Status",
    status: "Status",
    order_desc: "Your order will be ready by",
    store_info: "Store Info",
    product_info: "Product Info",
    information: "Information",
    delete_account: "Delete Account",
    cancel: "Cancel",
    your_account_will_be_deleted_permanently:
      "Your account will be deleted permanently",
    discover_store: "Discover Store",
    home: "Home",
    book_a_table: "Book a Table",
    can_not_find_store: "Can't find store",
    no_results_try_another_keyword: "No results. Try another keyword.",
    reservation_information: "Reservation Information",
    detail_information: "Detail Information",
    reservation_date: "Reservation date",
    reservation_time: "Reservation time",
    time: "Time",
    total_guest: "Total Guest",
    special_request_not_required: "Special Request ( not required )",
    placeholder_special_request_not_required:
      "Ex: special occasion, Table location, etc.",
    make_a_reservation: "Make a Reservation",
    restaurant_information: "Restaurant Information",
    as_seller: "as Seller",
    do_not_have_an_account: "Don't have an account?",
    remember_password: "Remember password",
    forget_password: "Forget password",
    forgot_password: "Forgot password",
    name_at_least_6_characters: "The {{text}} must be at least 6 characters",
    confirm_password_error: "Please input the same {{text}} above",
    agree_width: "Sign Up an account with myOKIES means you agree with our",
    agree_width_myoutie:
      "Sign Up an account with my-outie means you agree with our",
    terms_of_service: "Terms of service",
    privacy_policy: "Privacy policy",
    already_have_an_account: "Already have an account?",
    set: "Set",
    reset_password: "Reset Password",
    enter_your_otp: "Enter your OTP",
    sent_otp: "We have sent a one-time password (OTP)",
    to_your: "to your {{text}}",
    resend_now: "Resend now",
    did_not_receive_otp: "Didn't receive OTP",
    name_unsuccessful: "{{text}} UnSuccessful",
    not_permissions: "Not permissions",
    please_login_again_in_a_few_minutes: "Please login again in a few minutes",
    please_verify_your_account: "Please verify your account",
    booking: "Booking",
    back_homepage: "Back HomePage",
    a_reservation_confirmation_is_sent_to_your_email:
      "A Reservation Confirmation is sent to your email",
    page_not_found: "Page Not Found",
    no_data: "No data",
    please_choose_time_greater_than_current_time:
      "Please choose a time greater than the current time",
    about_us: "About Us",
    about_us_title: "The Future of POS Systems and Cashiers",
    about_us_content: "Transform your business operations with ",
    about_us_content_2:
      "- the all-in-one automation solution that streamlines the ordering and payment process. Our cutting-edge technology, using digital QR codes for menus, services, and products, delivers an exceptional customer service experience, improves efficiency, and reduce operation costs. Take your business to the next level with ",
    register_business_account: "Register Business Account",
    about_us_address:
      "Vincom Center Dong Khoi, Floor 17-11, 72 Le Thanh Ton, Ben Nghe Ward, District 1, HCMC, Vietnam",
    scan_qr_code_to_order_and_pay: "Scan QR Code to Order and Pay",
    online_and_offline_sales_in_one_app: "Online and Offline Sales in One App",
    unique_luxury_design: "Unique Luxury Design",
    modern_automation_technology: "Modern Automation Technology",
    multiple_payment_options: "Multiple Payment Options",
    online_operation_management: "Online Operation Management",
    fast_order_and_secure_payment: "Fast Order and Secure Payment",
    customer_loyalty_program: "Customer Loyalty Program",
    digital_qr_menu_or_product_list: "Digital QR Menu or Product List",
    more_customers_more_profit: "More Customers. More Profit",
    dedicate_for_hight_end_customer_shopping_and_dining_experience:
      "Dedicate for High-End Customer Shopping and Dining Experience",
    reduce_operation_cost: "Reduce Operation Cost",
    access_24_7_with_auto_sales_reports: "Access 24/7 with Auto Sales Reports",
    improve_customer_service_experience: "Improve Customer Service Experience",
    e_wallets_credit_debit_cards_and_cash_option:
      "E-Wallets, Credit/Debit Cards, and Cash Option.",
    reward_your_customers_width_point_redeemption_discount_price_and_many_more:
      "Reward Your Customers with Point Redeemption, Discount Price and many more.",
    main_features: "Main Features",
    business_application: "Business Application",
    food_and_beverage_businesses: "Food and Beverage Businesses",
    food_and_beverage_businesses_content:
      "From restaurants to coffee shops, process orders quickly with myOKIES - QR Menu. Simply scan QR Code to order and pay direclty on the phone. Manage your business in real time, free auto sales reports daily, monthly and yearly. Minimize your operation cost, and improve your customer service exprience.",
    retail_business: "Retail Business",
    retail_business_content:
      "Set your business apart from others with our technology service for online and in-store sales by purchasing through QR Code with the production information. Easily operate and manage your store entirely online, access 24/7 and accept all types of payment options from e-wallet to all debit/credit cards with myOKIES. Improve your customer shopping exprience, simply scan QR code to order, pay directly on app and receive the orders immediately.",
    service_businesses: "Service Businesses",
    service_businesses_content:
      "Quick and easy to order and pay for your service with myOKIES by scanning QR Code for Service List. Manage all aspects of your business completely online, access your management details from anywhere and anytime. Improve your customer service experience simply with a scannable QR code to order and pay directly on their phone and schedule appointments for your business with just a few clicks.",
    restaurant: "Restaurant",
    coffee_shop: "Coffee Shop",
    bar_lounge: "Bar, Lounge",
    hotel_resort_in_room_dining_service: "Hotel/Resort In-Room Dining Service",
    dessert_ice_cream_bakery_shop: "Dessert, Ice Cream, Bakery Shop",
    home_decor_furniture: "Home Decor, Furniture",
    wine_spirit_and_cigar: "Wine, Spirit and Cigar",
    flower_boutique_shop: "Flower Boutique Shop",
    airport_duty_free: "Airport Duty Free",
    book_store: "Bookstore",
    cosmetic: "Cosmetic",
    art_gallery: "Art Gallery",
    beauty_salon_hair_spa_nails_massage:
      "Beauty Salon - Hair, Spa, Nails, Massage",
    hotel_resort_in_room_spa_service: "Hotel/Resort In-Room Spa Service",
    gym_membership: "Gym Membership",
    event_ticket: "Event Ticket",
    health_care_clinic_hospital: "Health Care, Clinic, Hospital",
    vet_service: "Vet Service",
    casino_gambling_chips: "Casino Gambling Chips",
    our_service_is_currently_available_to:
      "Our service is currently available to:",
    tax_code: "Tax code",
    user_not_found: "User not found",
    are_you_sure_you_want_to_logout: "Are you sure you want to logout?",
    are_you_sure_you_want_to_cancel_this_reservation:
      "Are you sure you want to cancel this reservation?",
    back: "Back",
    welcome_back: "Welcome back, {{name}}",
    draft: "Draft",
    confirmed: "Confirmed",
    special_request: "Special Request",
    get_pdf_reservation: "Get PDF Reservation",
    cancel_reservation: "Cancel Reservation",
    business_brochure: "Business Brochure",
    eliminate_missing_order: "Eliminate Missing Order",
    orders_are_recorded_by_myokies_system_directly_from_customer_to_business_owner_management_dashboard:
      "Orders are recorded by myOKIES system directly from customer to bussiness owner management dashboard",
    inflight_dining: "Inflight Dining",
    your_account_has_been_verified: "Your account has been verified",
    other: "Other",
    and: "and",
    reservation_created:
      "Thank you for your reservation. Your request is pending confirmation. Store will review your reservation and send you an email confirmation as soon as possible.",
    banner_chart_title:
      "<span class='important'>myOKIES</span> vs. POS system Comparison Chart",

    please_choose: "Please choose {{text}}",
    category: "Category",
    search_products: "Search products",
    reservation: "Reservation",
    open: "Open",
    closed: "Closed",
    add_to_cart: "Add to Cart",
    name_successful: "{{text}} Successful",
    in_stock: "In stock",
    out_of_stock: "Out Of Stock",
    see_more: "See more",
    see_less: "See less",
    back_to_products: "Back To Products",
    you_may_also_like: "You may also like",
    your_cart: "Your Cart",
    your_cart_is_empty: "Your cart is empty",
    please_choose_company: "Please choose the company you want to pay",
    continue: "Continue",
    back_to_choose: "Back to choose",
    continue_to_check_out: "Continue to check out",
    remove: "Remove",
    note: "Note",
    user_name: "User Name",
    please_enter_your: "Please enter your {{text}}",
    email_address: "Email Address",
    phone_number: "Phone Number",
    service_method: "Service method",
    payment_method: "Payment method",
    location: "Location",
    address: "Address",
    credit_debit_card: "Credit / Debit Cards",
    atm_cart: "ATM Card",
    e_wallet: "E-Wallet",
    make_payment: "Make Payment",
    please_make_payment_at_pick_up_station:
      "Please make payment at pick-up station",
    back_to_your_cart: "Back To Your Cart",
    payment_failed: "The transaction is unsuccessful",
    payment_successful: "The transaction is successful",
    total_price: "Total Price",
    one_pay_error_99:
      "The transaction has been cancelled by card holder. Please try again.",
    check_order_detail: "Check Order Detail",
    continue_shopping: "Continue Shopping",
    order: "Order",
    order_completed: "Thank you so much for your order.",
    store_name: "Store Name",
    store_address: "Store Address",
    store_email: "Store Email",
    store_phone_number: "Store Phone Number",
    order_information: "Order Information",
    order_no: "Order No",
    date: "Date",
    store_information: "Store Information",
    payment_information: "Payment Information",
    delivery_option: "Delivery Option",
    delivery_address: "Delivery Address",
    payment_status: "Payment status",
    customer_information: "Customer Information",
    name: "Name",
    email: "Email",
    back_to_home: "Back To Home",
    pending: "Pending",
    paid: "Paid",
    cancelled: "Cancelled",
    table: "Location",
    pick_up: "Pick-up",
    delivery: "Delivery",
    cash: "Cash",
    processing: "Processing",
    change_language: "Change Language",
    choose_a_language: "Choose a language",
    send_receipt: "Send Receipt",
    the_store_is_closed: "The store is closed.",
    product_information: "Product Information",
    there_are_no_products_to_pay_for: "There are no products to pay for",
    maximum_name_is_60_characters: "Maximum name is 60 characters",
    your_name_is_not_valid: "{{text}} is not valid",
    booking_date: "Booking date",
    register_successful:
      "Welcome to myOKIES! Please kindly wait for myOKIES team to process your registration",
    register_successful_myoutie:
      "Welcome to my-outie! Please kindly wait for my-outie team to process your registration",
    all: "All",
    how_can_we_help_you: "How can we help you?",
    block: "Block",
    unblock: "Unblock",
    order_code_copied: "Order code {{text}} copied",
    you_are_discussing_this_product: "You are discussing this product",
    do_you_want_to_block_this_conversation:
      "Do you want to block this conversation?",
    do_you_want_to_unblock_this_conversation:
      "Do you want to unblock this conversation?",
    if_you_block_you_will_no_longer_be_able_to_message_this_person:
      "If you block, you will no longer be able to message this person",
    if_you_unblock_you_can_message_this_person:
      "If you unblock, you can message this person",
    you_have_been_blocked_by_this_user: "You have been blocked by this user",
    you_have_blocked_this_user: "You have blocked this user",
    chat: "Chat",
    no_conversation: "No conversation",
    chat_with_seller: "Chat with seller",
    you_have_a_new_message: "You have a new message",
    the_store_has_been_disabled: "The store has been disabled",
    okies_name: "OKIES TECHNOLOGY CORPORATION",
    issue_date: "Issue Date",
    okies_address:
      "72 Le Thanh Ton, FL 17-11, Ben Nghe, District 1, HCMC, Vietnam",
    product_management: "Product Management",
    no: "No.",
    price: "Price",
    created_on: "Created On",
    active: "Active",
    inactive: "Inactive",
    approval_status: "Approval status",
    activation_date: "Activation date",
    expiration_date: "Expiration date",
    expired: "Expired",
    approved: "Approved",
    pending_for_approval: "Pending for Approval",
    are_you_sure_you_want_to_delete_this_product:
      "Are you sure you want to delete this produt?",
    if_you_delete_this_product_you_will_never_see_it_again:
      "If you delete this product, you will never see it again.",
    import: "Import",
    sort: "Sort",
    filter: "Filter",
    field: "Field",
    ascending: "Ascending",
    descending: "Descending",
    select_from_computer: "Select file from computer",
    drag_and_drop_file_here: "Drag and drop files here",
    download: "Download",
    download_sample_product_list: "this sample product list upload",
    download_sample_product_list_continue:
      "to see what detail should be included",
    upload_file_error: "Upload file error",
    add_new: "Add new",
    action: "Action",
    product_name: "Product name",
    create_new_product: "Create new product",
    edit_product: "Edit product",
    quantity: "Quantity",
    discount: "Discount",
    amount: "Amount",
    percentage: "Percentage",
    description: "Description",
    enter_text: "Enter text...",
    image: "Image",
    add_image: "Add image",
    discount_bigger_price: "Discount should not bigger than the price",
    please_upload_picture_with_size_smaller_than_3mb_per_picture:
      "Please upload picture with size smaller than 3MB per picture",
    you_can_only_upload_file_jpg_pnd: "You can only upload JPG/PNG files!",
    you_can_only_upload_file_xlsx: "You can only upload XLSX files!",
    product_created: "Product has been created",
    product_updated: "Product has been updated",
    product_deteled: "Product deleted",
    as_business: "as Business",
    back_to_stores: "Back To Stores",
    full_name: "Full Name",
    choose_a_category: "Choose a category",
    you_are_discussing_this_order: "You are discussing this order",
    there_are_currently_no_products: "There are currently no products",
    cannot_create_product: "Cannot create product",
    cannot_update_product: "Cannot update product",
    minimum_image_is_1_image: "Minimum image is 1 image",
    maximum_images_are_20_images: "Maximum images are 20 images",
    rejected: "Rejected",
    quantity_invalid: "Quantity invalid",
  },
};

export default en;
