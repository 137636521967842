import { OrderStatusEnum } from "../order";

export enum SocketChannelEnums {
  ORDER_UPDATE_STATUS = "ORDER_UPDATE_STATUS",
  NEW_MESSAGE = "NEW_MESSAGE",
}

export interface RoomOrderUpdateStatusResponse {
  orderId: string;
  status: OrderStatusEnum;
  customerId: string;
}
