import { atom } from "recoil";

import { Conversation } from "./conversation.type";

interface ConversationList {
  data: Conversation[];
  page: number;
  pageSize: number;
  total: number;
  search?: string;
}

const list = atom<ConversationList>({
  key: "conversationList",
  default: {
    data: [],
    page: 1,
    pageSize: 5,
    total: 0,
  },
});

const totalMessageUnread = atom<number>({
  key: "totalMessageUnread",
  default: 0,
});

const ConversationAtom = { list, totalMessageUnread };

export default ConversationAtom;
