import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { toast } from "react-toastify";

import { AppScreens } from "~/router";
import { UserAtom, UserStatusEnum } from "~/services/user";
import Icon from "~/components/Icon";
import Input from "~/components/Input";
import LayoutContainer from "../LayoutContainer";
import Trans from "~/translation/translationKeys";
import { StorageConstant } from "~/services/storage";
import Logo from "~/assets/svgs/logo.svg";
import Button from "~/components/Button";
import Modal from "~/components/Modal/Modal";
import { CommonApi, CommonAtom } from "~/services/common";
import { ProductAtom } from "~/services/products";
import { socket } from "~/hooks/useSocket";
import { SocketChannelEnums } from "~/services/socket";
import { Message, MessageApi, MessageAtom } from "~/services/message";
import {
  Conversation,
  ConversationApi,
  ConversationAtom,
} from "~/services/conversation";

import "./Header.less";
import { CartAtom } from "~/services/cart";
import { calculateQuantityCarts } from "~/utils/calculateQuantityCart";

const Header: React.FC = () => {
  const [activeSearch, setActiveSearch] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const setProducts = useSetRecoilState(ProductAtom.productListFilter);
  const user = useRecoilValue(UserAtom.userInfo);
  const nav = useNavigate();
  const { t, i18n } = useTranslation();
  const [showModalMobile, setShowModalMobile] = useState(false);
  const isUserActive = user && user.status === UserStatusEnum.ACTIVE;
  const [openModalTranslate, setOpenModalTranslate] = useState(false);
  const locales = useRecoilValue(CommonAtom.locales);
  const setMessages = useSetRecoilState(MessageAtom.list);
  const [openChatModal, setOpenChatModal] = useRecoilState(
    CommonAtom.openChatModal
  );
  const setTotalMessageUnread = useSetRecoilState(
    ConversationAtom.totalMessageUnread
  );
  const setConversation = useSetRecoilState(ConversationAtom.list);
  const carts = useRecoilValue(CartAtom.cartList);
  const quantityCarts = calculateQuantityCarts(carts);

  const handleEnterSearch = () => {
    setActiveSearch(false);

    setProducts((prevProducts) => ({ ...prevProducts, name: searchValue }));
    nav(AppScreens.SEARCH);
  };

  useEffect(() => {
    if (activeSearch) {
      setActiveSearch(false);
    }

    CommonApi.getLocales();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (user) {
      ConversationApi.getList({
        page: 1,
        pageSize: 1,
        saveStore: false,
      }).then((res) => {
        setTotalMessageUnread(res.totalMessageUnread);
      });
    }
  }, [setTotalMessageUnread, user]);

  useEffect(() => {
    if (user) {
      socket.on(SocketChannelEnums.NEW_MESSAGE, async (newMessage: Message) => {
        if ([newMessage.sender?._id, newMessage.receiver].includes(user._id)) {
          if (newMessage.conversation === openChatModal.conversationId) {
            setMessages((currentMessages) => ({
              ...currentMessages,
              data: [...currentMessages.data, newMessage],
              offsetAddNotLoad: currentMessages.offsetAddNotLoad + 1,
            }));

            MessageApi.markAsRead(openChatModal.conversationId);
          } else {
            ConversationApi.getList({
              page: 1,
              pageSize: 1,
              saveStore: false,
            }).then((res) => {
              setTotalMessageUnread(res.totalMessageUnread);
            });
          }

          const item = await ConversationApi.getDetails(
            newMessage.conversation
          );

          if (newMessage.sender?._id !== user?._id) {
            toast.info(t(Trans.you_have_a_new_message), {
              style: {
                cursor: "pointer",
              },
              onClick: () => {
                setOpenChatModal({
                  open: true,
                  conversationId: newMessage.conversation,
                });
              },
            });
          }

          if (item) {
            setConversation((prevConversation) => {
              const data: Conversation[] = JSON.parse(
                JSON.stringify(prevConversation.data)
              );

              const foundItemIndex = data.findIndex(
                (conversationItem) =>
                  conversationItem._id === newMessage.conversation
              );

              if (foundItemIndex !== -1) {
                data.splice(foundItemIndex, 1);

                return {
                  ...prevConversation,
                  data: [
                    {
                      ...item,
                      updatedAt: newMessage.createdAt,
                      last_message: newMessage,
                    },
                    ...data,
                  ],
                };
              } else {
                data.splice(-1, 1);

                return {
                  ...prevConversation,
                  data: [item, ...data],
                };
              }
            });
          }
        }
      });
    }

    return () => {
      socket.off(SocketChannelEnums.NEW_MESSAGE);
    };
  }, [
    openChatModal.conversationId,
    setConversation,
    setMessages,
    setTotalMessageUnread,
    user,
    t,
    setOpenChatModal,
  ]);

  const handleChangeLanguage = (language: string) => {
    if (i18n.language === language) {
      return;
    }
    i18n.changeLanguage(language);
    localStorage.setItem(StorageConstant.LANGUAGE, language);
    window.location.reload();
  };

  const onCloseModalMobile = () => setShowModalMobile(false);

  return (
    <LayoutContainer className="header-bg" childrenClassName="header">
      {activeSearch ? (
        <React.Fragment>
          <Input
            placeholder={t(Trans.search_products)}
            variant="tertiary"
            maxWidth={588}
            className="w-full mx-auto"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            onKeyDown={(e) => e.key === "Enter" && handleEnterSearch()}
          />
          <Icon
            icon="cancel"
            className="header-close"
            width={40}
            height={40}
            onClick={() => setActiveSearch(false)}
          />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div className="header-left">
            <div
              className="header-item"
              onClick={() => nav(AppScreens.ABOUT_US)}
            >
              <Icon className="icon" icon="user-2" />
              <Link to={AppScreens.ABOUT_US} className="text">
                {t(Trans.about_us)}
              </Link>
            </div>
            <div className="header-item" onClick={() => nav(AppScreens.HOME)}>
              <Icon className="icon" icon="home" />
              <Link to={AppScreens.HOME} className="text">
                {t(Trans.products)}
              </Link>
            </div>
            <div className="header-item" onClick={() => setActiveSearch(true)}>
              <Icon className="icon" icon="search" />
              <Link
                to="#"
                className="text"
                onClick={() => setActiveSearch(true)}
              >
                {t(Trans.search)}
              </Link>
            </div>
          </div>

          <img
            src={Logo}
            alt="logo"
            width={87}
            height={58}
            className="cursor-pointer w-16 h-10 md:w-[87px] sm:h-[58px]"
            onClick={() => nav(AppScreens.HOME)}
          />

          <div className="header-mobile">
            <Icon
              className="icon"
              icon="search"
              onClick={() => setActiveSearch(true)}
            />
            <Icon
              className="icon"
              icon="ham-menu"
              onClick={() => setShowModalMobile(true)}
            />
          </div>

          <div className="header-right">
            <div
              className="header-item max-w-[250px]"
              onClick={() =>
                nav(isUserActive ? AppScreens.PROFILE : AppScreens.SIGN_IN)
              }
            >
              <Icon icon="user" className="icon" />
              {isUserActive ? (
                <Link to={AppScreens.PROFILE} className="text">
                  {t(Trans.welcome_back, {
                    name: user.name || "-",
                  })}
                </Link>
              ) : (
                <Link to={AppScreens.SIGN_IN} className="text">
                  {t(Trans.sign_in)}
                </Link>
              )}
            </div>
            <div className="header-item" onClick={() => nav(AppScreens.CART)}>
              <div className="relative">
                <Icon className="icon" icon="cart" />

                <span className="flex items-center justify-center absolute right-0 top-0 rounded-[30px] px-1 text-white text-[10px] bg-[#ff4343] translate-x-2 -translate-y-2 border border-white">
                  {quantityCarts >= 100 ? "+99" : quantityCarts}
                </span>
              </div>
              <Link to={AppScreens.CART} className="text">
                {t(Trans.cart)}
              </Link>
            </div>
            <Icon
              icon="global"
              width={20}
              height={20}
              className="cursor-pointer hover:!fill-[#ef6aaa]"
              onClick={() => setOpenModalTranslate(!openModalTranslate)}
            />
          </div>

          <div
            className={classNames("modal", {
              "!translate-x-0": showModalMobile,
            })}
          >
            <Icon
              icon="close"
              className="absolute cursor-pointer top-6 right-6"
              onClick={() => setShowModalMobile(false)}
            />

            <img
              src={Logo}
              alt="logo"
              width={96}
              height={64}
              className="mb-16 cursor-pointer"
              onClick={() => {
                onCloseModalMobile();
                nav(AppScreens.HOME);
              }}
            />

            {isUserActive && (
              <span className="text-lg font-medium text-center text-[#FAFAFA] mb-8">
                {t(Trans.welcome_back, {
                  name: user.name || "-",
                })}
              </span>
            )}

            <div className="flex flex-col gap-8">
              <div className="header-item">
                <Icon className="icon" icon="home" />
                <Link
                  to={AppScreens.HOME}
                  className="text"
                  onClick={onCloseModalMobile}
                >
                  {t(Trans.products)}
                </Link>
              </div>

              <div className="header-item">
                <Icon icon="user" />
                {isUserActive ? (
                  <Link
                    to={AppScreens.PROFILE}
                    className="text"
                    onClick={onCloseModalMobile}
                  >
                    {t(Trans.account)}
                  </Link>
                ) : (
                  <Link
                    to={AppScreens.SIGN_IN}
                    className="text"
                    onClick={onCloseModalMobile}
                  >
                    {t(Trans.sign_in)}
                  </Link>
                )}
              </div>

              <div className="header-item">
                <Icon className="icon" icon="user-2" />
                <Link
                  to={AppScreens.ABOUT_US}
                  className="text"
                  onClick={onCloseModalMobile}
                >
                  {t(Trans.about_us)}
                </Link>
              </div>
            </div>

            <div className="absolute bottom-0 left-6 right-6 flex justify-end border-t border-t-[#FAFAFA] py-6">
              <Button
                maxWidth={172}
                onClick={() => setOpenModalTranslate(true)}
              >
                {t(Trans.change_language)}
              </Button>
            </div>
          </div>
        </React.Fragment>
      )}

      <Modal
        open={openModalTranslate}
        title={t(Trans.choose_a_language)}
        footer={<></>}
        width={996}
        bodyStyle={{ height: "50vh", overflowY: "auto" }}
        variant="secondary"
        onCancel={() => setOpenModalTranslate(false)}
      >
        <div className="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-5 gap-y-4">
          {locales.map((locale) => (
            <div
              key={locale.code}
              className={classNames(
                "px-6 py-3 group hover:bg-[#666666] flex items-center justify-between cursor-pointer rounded-lg",
                {
                  "bg-[#666666]":
                    locale.code ===
                    localStorage.getItem(StorageConstant.LANGUAGE),
                }
              )}
              onClick={() => handleChangeLanguage(locale.code)}
            >
              <div className="flex items-center gap-x-2">
                <img
                  src={locale.flag}
                  className="object-contain w-5 h-5"
                  alt={locale.name}
                />
                <p
                  className={classNames(
                    "text-base text-white group-hover:text-[#ef6aaa] mb-0",
                    {
                      "text-[#ef6aaa]":
                        locale.code ===
                        localStorage.getItem(StorageConstant.LANGUAGE),
                    }
                  )}
                >
                  {locale.name}
                </p>
              </div>
              {locale.code ===
                localStorage.getItem(StorageConstant.LANGUAGE) && (
                <Icon icon="done" color="#ef6aaa" />
              )}
            </div>
          ))}
        </div>
      </Modal>
    </LayoutContainer>
  );
};

export default Header;
