import { ICart } from "~/services/cart";

export const calculateQuantityCarts = (carts: ICart[]): number => {
  let quantity: number = 0;

  carts.forEach((c) => {
    c.items.forEach((item) => (quantity += item.quantity));
  });

  return quantity;
};
