import { atom } from "recoil";

import { OrderStatusEnum } from "../order";

interface PaymentStatusModal {
  open: boolean;
  status: OrderStatusEnum;
  message: string;
  orderId: string;
  companyId: string;
  payment: boolean;
  customerId?: string;
}

export const paymentStatusModal = atom<PaymentStatusModal>({
  key: "paymentStatusModal",
  default: {
    open: false,
    status: 0,
    message: "",
    orderId: "",
    companyId: "",
    payment: false,
    customerId: "",
  },
});
